import React from 'react'
import { validateURL } from '../constants/validations'

function TextField({
    type,
    placeholder,
    onChange,
    value,
    required,
    name,
    active,
    readOnly = false
}) {
    const [isValidUrl, setIsValidUrl] = React.useState(false)

    const _onChange = (e) => {
        if (name === 'shopping_mall_url') {
            const valid = validateURL(e.target.value)
            setIsValidUrl(valid)
        }

        onChange(e)
    }

    return (
        <div className='relative w-full'>
            <input
                id={name}
                type={type}
                name={name}
                required={required}
                onChange={_onChange}
                defaultValue={value}
                placeholder={placeholder}
                readOnly={readOnly}
                className={`border-none outline-none bg-transparent transition-all ease-in duration-100 w-full text-sm ${active ? 'text-black' : 'text-themeBlack-300'} placeholder:text-themeGrey-150 ${isValidUrl && '!active-color'}`}
            />
        </div>
    )
}

export default TextField
