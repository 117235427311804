import { BASE_URL } from "../constants";
import { getToken } from "../utils/getToken";

export const getMyNotification = async () => {
    try {
        const response = await fetch(`${BASE_URL}/notification`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                token: getToken()
            }
        });
        const data = await response.json();
        if (response.status === 200)
            return data.notifications
    } catch (error) {
        console.log(error);
    }
    return []
}

export const markAsRead = async (_id) => {
    try {
        const response = await fetch(`${BASE_URL}/notification/markAsRead/${_id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                token: getToken()
            }
        });
    } catch (error) {
        console.log(error);
    }
    return []
}