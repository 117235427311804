import React from 'react'
import { useTranslation } from 'react-i18next'

function Activation() {
    const [t] = useTranslation("global")
  return (
    <div className='flex justify-center items-center w-full pt-20 sm:h-screen sm:pt-0'> 
        <div className='bg-white rounded-2xl border-[1px] border-themeGrey-70 flex flex-col gap-2 px-6 py-8 w-1/2 shadow-sm sm:w-[90%]'>
                <h2 className='text-base font-bold text-themePink text-center'>{t("activation")}</h2>
                <p className='text-sm text-themeBlack-200 text-center'>{t('activation_text')}</p>
        </div>
    </div>
  )
}

export default Activation
