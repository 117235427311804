import React from 'react'

function Loader() {
    return (
        <div className='w-full h-40 flex justify-center items-center'>
            <div class="loader loader-3">
                <div></div><div></div><div></div>
            </div>
        </div>
    )
}

export default Loader
