import React, { useEffect, useRef } from 'react';
import { FaRegDotCircle } from 'react-icons/fa';
import { IoIosArrowDown } from 'react-icons/io';

function Select({
  options,
  name,
  onChange,
  placeholder,
  value,
  activeVal,
  readOnly = false,
  simple = false
}) {
  const [active, setActive] = React.useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setActive(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  const selectedOption = value && options.filter(o => o.value === value)[0]

  return (
    <div className={`relative w-full ${simple? "flex justify-start items-start gap-2 py-2.5 px-4 border-[1px] rounded-xl border-themeGrey-100 shadow-sm": ""}`} ref={ref}>
      <div onClick={() => {if(!readOnly) setActive(!active)}} className='flex justify-between items-center w-full cursor-pointer'>
        <p className={`flex justify-start items-center gap-2 text-sm ${activeVal ? simple ? 'text-themePink' : 'text-black' : 'text-themeBlack-300'}`}>
          {selectedOption?.icon &&  <img src={selectedOption.icon} alt='icon' className='w-5 h-5 object-cover'/>}
          {selectedOption? selectedOption.label : placeholder}
        </p>
        <IoIosArrowDown className='text-themePink text-lg' />
      </div>

      {active && (
        <div className={`absolute ${simple? 'right-0 top-10 w-full' : '-right-4 top-8 w-[80%]'} rounded-lg glass border-[1px] border-themeGrey-100 z-40 shadow-md overflow-hidden mb-10`}>
          <ul className='w-full max-h-52 overflow-y-auto z-50 relative'>
            {options.map((option, index) => (
              <li
                key={index}
                onClick={() => {
                  onChange({
                    target: {
                      name,
                      value: option?.value,
                      ...option
                    }
                  });
                  setActive(false);
                }}
                className={`flex justify-between items-center py-2 px-4 gap-1 ${option?.value === value && 'bg-white'} hover:bg-white cursor-pointer text-sm border-b-[1px] border-themeGrey-100 text-themeBlack-300`}
              >
                <div className='flex justify-start items-center gap-2'>
                {option.icon && <img src={option.icon} alt='icon' className='w-5 h-5 object-cover'/>}
                {option?.label}
                </div>
                <FaRegDotCircle className={`${option?.value === value ? 'text-themePink' : 'text-themeBlack-400'} text-sm`} />
              </li>
            ))}
          </ul>
          <div className='absolute bottom-0 w-1/2 h-10 bg-themePink blur-3xl opacity-60 z-0' />
          <div className='absolute -top-2 right-0 w-1/2 h-10 bg-themePink blur-3xl opacity-50 z-0' />
        </div>
      )}
    </div>
  );
}

export default Select;
