import React, { createContext, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'


export const Context = createContext()

function NavbarProvider({ children }) {
  const [query, setQuery] = React.useState({ type: 'Product', category: '', search: '', platform: '', region: '', sort: 'latest' })
  const navigate = useNavigate()
  const [first, setFirst] = React.useState(true)
  const location = useLocation()


  useEffect(() => {
    if (location.pathname !== '/' && !first){
      navigate('/')
    }
  
  
  }, [query])
  

  return (
    <Context.Provider value={{ query, setQuery, setFirst }}>
      {children}
    </Context.Provider>
  )
}

export default NavbarProvider
