import { BASE_URL } from "../constants";
import { getToken } from "../utils/getToken";

export const getOrderData = async (id) => {
    try {
        const response = await fetch(`${BASE_URL}/order/getOrderData/${id}`,{
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'token': getToken()
            }
        })
        const res = await response.json()
        if (response.status === 200){
           return res.data
        }
    } catch (error) {
        console.log(error);
    }
    return null
}