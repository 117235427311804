import { parsePhoneNumberFromString } from 'libphonenumber-js';

export const validateEmail = (email) => {
    const emailRegex = /(?:[!#-'*+/-9=?A-Z^-~-]+(?:\.[!#-'*+/-9=?A-Z^-~-]+)*|\"(?:\[\]!#-[^-~\t]|(\\[\t-~]))+\")@(?:(?:[!#-'*+/-9=?A-Z^-~-]+(?:\.[!#-'*+/-9=?A-Z^-~-]+)*)|\[(?:[\t-Z^-~]*)\])/;
    return emailRegex.test(email);
};


export const validateNumber = (phoneNumber) => {
    const phoneNumberObject = parsePhoneNumberFromString(phoneNumber);
    if (!phoneNumberObject) {
        return false;
    }

    return phoneNumberObject.isValid();
};


export const validateDoB = (dob) => {
    return new Date(dob) < new Date();
}

export const validatePassword = (password) => {
    const criteria = {
        minLength: password.length >= 8,
        hasLetter: /[a-zA-Z]/.test(password),
        hasNumber: /[0-9]/.test(password),
        hasSymbol: /[!@#$%^&*()_+{}|:"<>?]/.test(password)
    };

    let completeCount = 0;
    for (let key in criteria) {
        if (criteria[key]) {
            completeCount++;
        }
    }
    const completionPercentage = Math.floor((completeCount / Object.keys(criteria).length) * 100);

    const result = {
        minLength: criteria.minLength,
        hasLetterAndNumber: criteria.hasLetter && criteria.hasNumber,
        hasSymbol: criteria.hasSymbol,
        completionPercentage: completionPercentage
    };

    return result;
};

export const validateBankAccountNumber = (value) => {
    return value.length > 4 
}

export const validateComapnyName = (value) => {
    return value.length > constraints.company_name.min && value.length < constraints.company_name.max;
}
export const validateName = (value) => {
    return value?.length > constraints.name.min && value.length <= constraints.name.max;
}
export const validateAddress = (value) => {
    return value.length > constraints.company_address.min && value.length <= constraints.company_address.max;
}
export const validatehouse_number = (value) => {
    return value.length >= constraints.house_number.min && value.length <= constraints.house_number.max;
}

export const validateBRegNo = (breg = '') => {
    const regex = /^\d{3}-\d{2}-\d{5}$/;
    console.log(regex.test(breg), breg)
    return regex.test(breg)
};

export const validateFreeLancerTax = (value) => {
    return constraints.freelancer_tax.min === value.length;
}

export const validateRegistrationDate = (date) => {
    return new Date(date) < new Date();
}

export const validateNickname = (value) => {
    const isValidFormat = /^[a-zA-Z0-9]+$/.test(value);
    return isValidFormat && value.length > constraints.nickname.min && value.length <= constraints.nickname.max;
}

export const validateTags = (tags) => {
    return tags.length > 0;
}

export const validateBio = (value) => {
    return value.length > constraints.bio.min && value.length <= constraints.bio.max;
}

export const validateURL = (value = '') => {
    const urlRegex = new RegExp(
        '^(https?:\\/\\/)' + // Require protocol http or https
        '((([a-zA-Z\\d]([a-zA-Z\\d-]*[a-zA-Z\\d])*)\\.)+[a-zA-Z]{2,})' + // Valid domain name and extension
        '(\\:\\d+)?(\\/[-a-zA-Z\\d%_.~+]*)*' + // Port and path
        '(\\?[;&a-zA-Z\\d%_.~+=-]*)?' + // Query string
        '(\\#[-a-zA-Z\\d_]*)?$' // Fragment locator
      );
    // const urlRegex = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}([\/a-zA-Z0-9#?&%=._-]*)?$/;

    return value !== "" && urlRegex.test(value)
}

export const constraints = {
    house_number:{ 
        min: 1,
        max: 200
    },
    name: {
        min: 1,
        max: 50,
    },
    nickname: {
        min: 4,
        max: 10,
    },
    company_name: {
        min: 3,
        max: 70,
    },
    company_address: {
        min: 3,
        max: 70,
    },
    freelancer_tax: {
        min: 7,
        max: 7,
    },
    bio: {
        min: 1,
        max: 200
    },
    product_description: {
        min: 3,
        max: 1100
    },
    campaign_title: {
        min: 3,
        max_word: 6
    },
    tags: {
        profile: 10,
        campaign: 5
    },
    description: {
        min: 1,
        max: 50
    },
    types_of_submitted_results: {
        min: 3,
        max: 600
    },
    what_to_do: {
        min: 3,
        max: 600
    },
    what_should_be_emphasized: {
        min: 3,
        max: 600
    },
    what_not_to_do: {
        min: 3,
        max: 600
    },
    body_keyword: {
        min: 3,
    },
    body_keyword: {
        min: 1,
    },
    title_keyword: {
        min: 1,
    },
    tags: {
        min: 1,
    },
    product_offer: {
        min: 3,
    },
    visit_address: {
        min: 3,
    },
    how_to_purchase: {
        min: 3,
    },
    express_thoughts: {
        min: 3,
        max: 200
    }
}