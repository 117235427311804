import { formatDate } from "./formatDate";

export const groupMessagesByDate = (messages) => {
    return messages.reduce((groups, message) => {
        const date = formatDate(message.createdAt);
        if (!groups[date]) {
            groups[date] = [];
        }
        groups[date].push(message);
        return groups;
    }, {});
};