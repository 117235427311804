import { BASE_URL } from "../constants"
import { getToken } from "../utils/getToken";

export const verifyOTP = async (otp, orderId = null) => {
    try {
        let user = {}
        if(window.location.pathname.includes('/change-password'))
            user = JSON.parse(localStorage.getItem('user'))
        else
            user = JSON.parse(localStorage.getItem('newUser'))
        
        if(orderId)
             user = JSON.parse(localStorage.getItem('user'))
        const response = await fetch(`${BASE_URL}/user/verifyOTP`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                token: getToken()
            },
            body: JSON.stringify({
                userId: user._id,
                otp,
                orderId
            })
        })
    
        return response;
    } catch (error) {
        console.log(error);
    }
}