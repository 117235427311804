import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ProfileTabs from './ProfileTabs'
import InputGroup from './InputGroup'
import Button from './Button'
import { getCategories } from '../services/getCategories'
import { updateMyProfile } from '../services/updateMyProfile'
import { constraints, validateAddress, validateBankAccountNumber, validateBio, validateBRegNo, validateComapnyName, validateFreeLancerTax, validatehouse_number, validateName, validateNickname, validateNumber, validateRegistrationDate, validateTags, validateURL } from '../constants/validations'
import { updateUserSocials } from '../services/updateUserSocials'
import { toast } from 'react-toastify'
import Input from './Input'
import { getMyProfile } from '../services/getMyProfile'
import Nickname from './Nickname'
import Address from './Address'
import Guide from './Guide'
import { fetchRegions } from '../services/fetchRegions'
import { Context } from '../Provider/LangaugeProvider'
import { PLATFORMS } from '../constants'
import { getBanks } from '../services/getBanks'
import { Link } from 'react-router-dom'

function MyData() {
  const [user, setUser] = React.useState(JSON.parse(localStorage.getItem('user')))
  const [t] = useTranslation("global")
  const [basicDone, setBasicDone] = React.useState(false)
  const [infoDone, setInfoDone] = React.useState(false)
  const [socialDone, setSocialDone] = React.useState(false)
  const [banks, setBanks] = React.useState([])
  const { siteLang } = React.useContext(Context)

  useEffect(() => {
    const get = async () => {
      const bankOptions = await getBanks(true, siteLang)
      setBanks(bankOptions)
    }
    get()
  }, [siteLang])

  const [active, setActive] = React.useState(0)

  useEffect(() => {
    const getData = async () => {
      const data = await getMyProfile()
      setUser(data)
    }

    getData()

  }, [active])


  useEffect(() => {
    const updateProfile = async () => {
      if (active === 0) {
        user.bank = user.bank || banks[0]?.value
        await updateMyProfile(user, 'basic')
      }
      else if (active === 1)
        await updateMyProfile(user, 'info')
    }
    updateProfile()
  }, [user])


  useEffect(() => {
    if (user) {
      const hasAccountAddress = Object.values(user.socials || {})?.some(
        (social) => {
          // console.log(social);
          return social?.account_address && social.account_address.length > 0
        }
      );

      if (hasAccountAddress) {
        setSocialDone(true);
      }
      else {
        setSocialDone(false);
      }

      if (
        validateNickname(user.nickname ?? '') &&
        validateBio(user.bio ?? '') &&
        validateTags(user.tags ?? '') &&
        user.category?.length > 1 &&
        validateAddress(user.address ?? '') &&
        validatehouse_number(user.house_number ?? '') 
      ) {
        setInfoDone(true)
      }
      else {
        setInfoDone(false)
      }

      console.log(
        validateNickname(user.nickname ?? '') ,
        validateBio(user.bio ?? '') ,
        validateTags(user.tags ?? ''),
        user.category?.length > 1,
        validateAddress(user.address ?? '') ,
        validatehouse_number(user.house_number ?? '') 
      );
      
      

      if (
        (user.role === 'creator' || validateComapnyName(user.company_name ?? '')) &&
        (user.role === 'creator' || validateBRegNo(user.business_reg_no ?? '')) &&
        (user.role === 'customer' || validateFreeLancerTax(user.freelancer_tax ?? '')) &&
        new Date(user.dob) < new Date() &&
        (user.role === 'customer' || validateBankAccountNumber(user.bank_account_no ?? '')) &&
        validateName(user.name ?? '') &&
        validateNumber(user.phone ?? '')
      ) {
        setBasicDone(true)
      }
      else {
        setBasicDone(false)
      }
      
    }

  }, [user])

  return (
    <div className='flex flex-col gap-4 sm:gap-0'>

      <div className='px-5 pt-6 pb-6 flex flex-col gap-6 bg-white rounded-lg border-[1px] border-themeGrey-500 sm:border-none sm:pt-0'>
        <ProfileTabs user={user} setActive={setActive} active={active} basicDone={basicDone} infoDone={infoDone} socialDone={socialDone} />
        <div className='flex flex-col gap-6'>
          {active === 0 && <Form1 user={user} setUser={setUser} setActive={setActive} setBasicDone={setBasicDone} banks={banks} />}
          {active === 1 && <Form2 user={user} setUser={setUser} setActive={setActive} setInfoDone={setInfoDone} />}
          {active === 2 && <Form3 user={user} setUser={setUser} setActive={setActive} setSocialDone={setSocialDone} />}
        </div>
      </div>
    </div>
  )
}

export default MyData


const Form1 = ({ user, setUser, banks }) => {
  const [t] = useTranslation("global")
  const [loading, setLoading] = React.useState(false)
  const [activeBtn, setActiveBtn] = React.useState(false)
  const [error, setError] = React.useState(null)
  const debounceTimeout = useRef(null)
  const { siteLang } = React.useContext(Context)

  const onChange = (e) => {
    const value = e.target.value;
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    debounceTimeout.current = setTimeout(() => {
      setUser({ ...user, [e.target.name]: value })
    }, 100);
  }



  return (
    <div className='flex flex-col gap-4 sm:gap-5 relative  sm:pb-16'>
      <span className='w-full sm:w-full'>
        <Guide heading={t("basic_guid")} text={t("basic_guide_text")} />
      </span>

      <InputGroup
        label={t("email") + " *"}
        type="text"
        required={true}
        value={user?.email}
        disabled={true}
        readOnly={true}
        flex="flex-col gap-1.5"
      >
      </InputGroup>
      <div className='flex justify-between items-end gap-3'>
        <InputGroup
          label={t("password")}
          type="password"
          required={true}
          value={"********"}
          name=""
          flex="flex-col gap-1.5"
          placeholder={t("")}
          readOnly={true}
          disabled={true}
        >
        </InputGroup>
        <Link to='/change-password/verify-email' className='w-1/4'>
          <Button text={t('change')} active={true} disabled={false} />
        </Link>

      </div>
      <InputGroup
        label={t("name") + " *"}
        type="text"
        required={true}
        value={user?.name}
        name="name"
        onChange={onChange}
        flex="flex-col gap-1.5"
        placeholder={t("Profile_title_placeholder")}
      >
        <p className='text-xs text-themeGrey-50'>{t("profile_title_constraint")}</p>
      </InputGroup>
      <InputGroup
        label={t("phone") + " *"}
        type="phone"
        required={true}
        value={user?.phone}
        name="phone"
        onChange={onChange}
        flex="flex-col gap-1.5"
      >
      </InputGroup>
      <InputGroup
        label={t("dob") + " *"}
        type="date"
        required={true}
        value={user?.dob?.substr(0, 10)}
        name="dob"
        onChange={onChange}
        flex="flex-col gap-1.5"
      >
      </InputGroup>
      {user.role === 'creator' && <>
        <hr className='w-full h-0.5 border-b-[1px] border-themeGrey-70 my-3 mb-2' />
        <h3 className='text-base font-semibold text-themeBlack-500 sm:my-2 mb-3'>{t("bank_info")}</h3>
        <InputGroup
          label={t("bank_name")}
          type="select"
          required={true}
          value={user?.bank || banks[0]?.value}
          name="bank"
          onChange={onChange}
          options={banks}
          placeholder={t("Select")}
        />
        <InputGroup
          label={t("account_number")}
          type="number"
          required={true}
          value={user?.bank_account_no}
          name="bank_account_no"
          onChange={onChange}
          placeholder={t("enter_Acc_no")}
        />
      </>}

      {user.role === 'customer' && <>
        <hr className='w-full h-0.5 border-b-[1px] border-themeGrey-70 my-3 mb-2' />
        <h3 className='text-base font-semibold text-themeBlack-500  sm:my-2 mb-3'>{t("c_info")}</h3>
        <InputGroup
          label={t("c_name")}
          type="text"
          required={true}
          value={user?.company_name}
          name="company_name"
          onChange={onChange}
          placeholder={t("c_name_placeholder")}
        />
        <InputGroup
          label={t("c_designation")}
          type="select"
          required={true}
          value={user?.company_designation}
          name="company_designation"
          onChange={onChange}
          options={[
            { value: "Executives", label: siteLang === 'kor'? "대표" : "Executives" },
            { value: "Employee", label: siteLang === 'kor'? "직원" : "Employee" },
            { value: "Agency", label: siteLang === 'kor'? "대행사" : "Agency" }
          ]
          }
          placeholder={t("Select")}
        />
        <InputGroup
          label={t("b_reg_no")}
          type="text"
          required={true}
          value={user?.business_reg_no}
          name="business_reg_no"
          onChange={onChange}
          placeholder={t("OOO-OO-OOOOO")}
        />

      </>}

      {user.role === 'creator' && <>
        <hr className='w-full h-0.5 border-b-[1px] border-themeGrey-70 my-3 mb-2' />
        <InputGroup
          label={t("freelancer_tax")}
          type="number"
          required={true}
          value={user?.freelancer_tax}
          name="freelancer_tax"
          onChange={onChange}
          placeholder={t("OOOOOOO")}
        />
      </>}

      {error && <p className='text-red-600 text-sm'>{error}</p>}
      {/* <div className='w-1/3 mt-2 sm:w-full sm:fixed sm:left-0 sm:bottom-0'>
        <div className='sm:px-4 sm:py-4 sm:bg-white'>
          <Button text={t("save_")} active={activeBtn} disabled={loading} onClick={onSubmit} />
        </div>
      </div> */}
    </div>
  )
}

const Form2 = ({ user, setUser, setActive, setInfoDone }) => {
  const { siteLang } = React.useContext(Context)
  const [t] = useTranslation("global")
  const [loading, setLoading] = React.useState(false)
  const [activeBtn, setActiveBtn] = React.useState(false)
  const [categories, setCategories] = React.useState([])
  const [error, setError] = React.useState(null)
  const [regions, setRegions] = useState([]);
  const [selectedStete, setSelectedState] = useState(null);
  const debounceTimeout = useRef(null);
  const [callAPI, setCallApi] = useState(false)


  const onChange = (e) => {
    const value = e.target.value;
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    debounceTimeout.current = setTimeout(() => {
      if (e.target.name === 'state') {
        setUser({ ...user, state: value, city: '' })
      }
      else
        setUser({ ...user, [e.target.name]: value })
    }, 1000);
  }


  useEffect(() => {
    const categoryoption = async () => {
      const res = await getCategories(true, siteLang)
      setCategories(res)
    }
    const getRegions = async () => {
      const regions = await fetchRegions();
      const options = regions.map(region => ({ value: region.name, label: region.name, cities: region.cities }))
      const userState = regions.filter(r => r.name === user.state)[0]
      setSelectedState(userState)
      setRegions(options);
    }
    getRegions();
    categoryoption()
  }, [])


  return (
    <div className='flex flex-col gap-4 sm:gap-5 relative sm:pb-16'>
      <Nickname user={user} />

      <InputGroup
        label={user.role === 'customer'? t("ProfileDescription") : t('creatorProfileDescription') + " *"}
        type="textarea"
        required={true}
        value={user?.bio}
        name="bio"
        onChange={onChange}
        flex="flex-col gap-1.5"
        textAreaSimple={true}
        placeholder={t("Profile_description_placeholder")}
      >
        <p className='text-xs text-themeGrey-50'>{t("profile_description_constraint")}</p>
      </InputGroup>
      <InputGroup
        label={user.role === 'customer'?  t("tags"): t("creatorTags") + " *"}
        type="tag"
        required={true}
        value={user?.tags}
        name="tags"
        onChange={onChange}
        flex="flex-col gap-1.5"
      >
        <p className='text-xs text-themeGrey-50'>{t("tags_constraint")}</p>
      </InputGroup>
      <InputGroup
        label={t("category") + " *"}
        type="select"
        required={true}
        value={user?.category}
        name="category"
        onChange={onChange}
        options={categories}
        flex="flex-col gap-1.5"
        placeholder={t("category_placeholder")}
      />
      <Address setUser={setUser} user={user} />
      <div className='flex gap-4'>
        <InputGroup
          label={t("state") + " *"}
          type="select"
          required={true}
          value={user?.state}
          name="state"
          onChange={(e) => { onChange(e); setSelectedState(e.target) }}
          options={regions}
          simple={true}
          flex="flex-col gap-1.5"
          placeholder={t("select_state")}
        />
        {selectedStete?.cities.length > 0 && <InputGroup
          label={t("city") + " *"}
          type="select"
          required={true}
          value={user?.city}
          name="city"
          onChange={onChange}
          simple={true}
          options={selectedStete.cities.map(c => ({ value: c.name, label: c.name }))}
          flex="flex-col gap-1.5"
          placeholder={t("select_city")}
        />}
      </div>

      <InputGroup
        label={t("Shopping_mall_url")}
        type="text"
        required={true}
        value={user?.shopping_mall_url}
        name="shopping_mall_url"
        onChange={onChange}
        flex="flex-col gap-1.5"
      >
        <p className='text-xs text-themeGrey-50'>{t("optional")}</p>
      </InputGroup>

      {user?.role === 'creator' && <>
        <h2 className='text-themeBlack-300 text-sm font-semibold'>{t('sPrefre')}</h2>
        <Input
          type="switch"
          placeholder={t("onlineServer")}
          value={user?.online_service}
          name="online_service"
          onChange={onChange}
        />
        <Input
          type="switch"
          placeholder={t("offlineServer")}
          value={user?.offline_service}
          name="offline_service"
          onChange={onChange}
        />
      </>}

      {error && <p className='text-red-600 text-sm'>{error}</p>}
      <div className='w-1/3 mt-2 sm:w-full sm:fixed sm:left-0 sm:bottom-0'>
        {/* <div className='sm:px-4 sm:py-4 sm:bg-white'>
          <Button text={t("save_")} active={activeBtn} disabled={loading} onClick={onSubmit} />
        </div> */}
      </div>
    </div>
  )
}

const Form3 = ({ user, setUser, setActive, setSocialDone }) => {
  const { siteLang } = React.useContext(Context)


  const { t } = useTranslation("global");
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [data, setData] = React.useState(user?.socials ?? {
    youtube: { account_address: '', },
    instagram: { account_address: '', },
    tiktok: { account_address: '', },
    naver_blog: { account_address: '', },
    x: { account_address: '', },
  });

  // console.log("data", data);
  // console.log("socials", user.socials);

  const onChange = (e, platformKey, fieldName) => {
    const newValue = e.target.value?.replace(/[^a-zA-Z0-9._]/g, '');

    setData(prevData => ({
      ...prevData,
      [platformKey]: {
        ...prevData[platformKey],
        [fieldName]: newValue?.length <= 0 ? '' : `${PLATFORMS.find(p => p.key.includes(platformKey)).url}${newValue}`
      }
    }));


  };


  const onSubmit = async () => {
    setLoading(true);
    setError(null)
    const res = await updateUserSocials({ socials: data });
    if (res === true) {
      toast.success("Social medias updated successfully")
    }
    else {
      setError(res.message)
    }
    setLoading(false);
  };


  const getMainPlatformKey = (key) => key.split('_')[0];

  const uniquePlatforms = [];
  const seenKeys = new Set();

  PLATFORMS.forEach((platform) => {
    const mainKey = getMainPlatformKey(platform.key);
    if (!seenKeys.has(mainKey)) {
      seenKeys.add(mainKey);
      uniquePlatforms.push({...platform, key: mainKey});
    }
  });

  useEffect(() => {
    setUser(prev => ({ ...prev, socials: data }))
  }, [data])


  const socials = JSON.parse(localStorage.getItem('user') || "{}")?.socials;


  


  return (
    <div className='flex flex-col gap-4 sm:pb-16 relative'>
      <span className='w-full sm:w-full'>
        <Guide heading={t("sns_guide")} text={t("sns_guide_text")} />
      </span>
      {uniquePlatforms.map((platform, index) => (
        <div key={platform.key} className='flex flex-col gap-2'>
          <div className='flex justify-start items-start gap-2 mb-0'>
            <img src={platform.logo} className='w-8 h-8 rounded-full object-cover' alt={platform.title} />
            <p className='text-base font-semibold text-themeBlack-500 mt-1'>{siteLang === 'kor' ? platform.kor_title : platform.title}</p>
          </div>
          <div className='flex justify-between items-end gap-2'>
            <InputGroup
              label={t("AccountAddress")}
              type="url"
              required={true}
              value={data[platform.key]?.account_address?.split('/').pop()}
              name={`${platform.key}_account_address`}
              flex='flex-col gap-1.5'
              onChange={(e) => onChange(e, platform.key, 'account_address')}
            />
            <span className='w-1/4'>
              <Button text={socials && socials[platform.key]?.account_address?.split('/').pop().length > 1 ? t('Modify') : t('register')} active={validateURL(data[platform.key]?.account_address)} disabled={loading} onClick={onSubmit} />
            </span>
          </div>
          <p className='text-xs text-themePink font-medium'>{data[platform.key]?.account_address}</p>
        </div>
      ))}
      {/* {error && <p className='text-red-600 text-sm'>{error}</p>}
      <div className='w-1/3 mt-5 sm:w-full sm:fixed sm:left-0 sm:bottom-0'>
        <div className='sm:px-4 sm:py-4 sm:bg-white'>
          <Button text={t("save")} active={true} disabled={loading} onClick={onSubmit} />
        </div>
      </div> */}
    </div>
  );
};
