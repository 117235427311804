import React from 'react'
import moment from 'moment-timezone'
import { formateDate } from '../../utils/formateDate'
import { useTranslation } from 'react-i18next';

function Scheduling({ campaign, }) {
    const [t] = useTranslation('global')
    const created_at = moment(campaign.createdAt).tz('Asia/Seoul').startOf('day');
    const startDate = moment(campaign.start_date).startOf('day');
    const recruitEndDate = startDate.clone().add(2, 'days').endOf('day');
 
    const approveEndDate = recruitEndDate.clone().add(1, 'days');

    const deliveryVistDate = approveEndDate.clone().add(3, 'days');

    const activeDays = 10;

    const activeEndDate = deliveryVistDate.clone().add(activeDays, 'days');
    const confirmEndDate = activeEndDate.clone().add(1, 'days');
    const EndDate = confirmEndDate.clone().add(14, 'days');
    return (
        <div className='flex flex-col gap-1'>
            <div className='flex justify-between items-center'>
                <p className='text-sm text-themeBlack-200 font-medium'>{t('reg_date')}</p>
                <p className='text-sm text-themeBlack-200 font-medium'>{formateDate(created_at)}</p>
            </div>
            <div className='flex justify-between items-center'>
                <p className='text-sm text-themeBlack-200 font-medium'>{t('recruit_start_date')}</p>
                <p className='text-sm text-themeBlack-200 font-medium'>{formateDate(startDate)}</p>
            </div>
            <div className='flex justify-between items-center'>
                <p className='text-sm text-themeBlack-200 font-medium'>{t('recruit_end_date')}</p>
                <p className='text-sm text-themeBlack-200 font-medium'>{formateDate(recruitEndDate)}</p>
            </div>
            <div className='flex justify-between items-center'>
                <p className='text-sm text-themeBlack-200 font-medium'>{t('approve_end_date')}</p>
                <p className='text-sm text-themeBlack-200 font-medium'>{formateDate(approveEndDate)}</p>
            </div>
            {campaign.campaign_option === "10%" &&
                <div className='flex justify-between items-center'>
                    <p className='text-sm text-themeBlack-200 font-medium'>{t('reservation_date')}</p>
                    <p className='text-sm text-themeBlack-200 font-medium'>{formateDate(campaign.reservation_date)}</p>
                </div>}
            <div className='flex justify-between items-center'>
                <p className='text-sm text-themeBlack-200 font-medium'>{t('active_end_date')}</p>
                <p className='text-sm text-themeBlack-200 font-medium'>{formateDate(activeEndDate)}</p>
            </div>
            <div className='flex justify-between items-center'>
                <p className='text-sm text-themeBlack-200 font-medium'>{t('end_date')}</p>
                <p className='text-sm text-themeBlack-200 font-medium'>{formateDate(EndDate)}</p>
            </div>
        </div>
    )
}

export default Scheduling
