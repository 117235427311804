import React, { useEffect, useState } from 'react'
import Button from './Button'
import { useTranslation } from 'react-i18next'
import { RxCross1 } from 'react-icons/rx'
import { useParams } from 'react-router-dom'
import InputGroup from './InputGroup'
import { constraints, validateURL } from '../constants/validations'
import Input from './Input'
import SecondaryButton from './SecondaryButton'
import { FaImage } from 'react-icons/fa'
import StoreOTP from './StoreOTP'
import { verifyOTP } from '../services/verifyOTP'
import { toast } from 'react-toastify'
import { imageUpload } from '../services/ImageUpload'
import { deliverOrder } from '../services/deliverOrder'

function DeliverPopup({ campaign, order, setPopup, setRefresh }) {
    const [t] = useTranslation('global')
    const [data, setData] = useState({ express_thoughts: '' })
    const [links, setLinks] = useState([''])
    const [activeBtn, setActiveBtn] = useState(false)
    const [files, setFiles] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const [otp, setOtp] = React.useState('')
    const type = campaign.campaign_type

    const { orderId } = useParams()

    const handleChange = (index, event) => {
        const newLinks = [...links];
        newLinks[index] = event.target.value;
        setLinks(newLinks);

        if (newLinks[index] && index === links.length - 1 && links.length < 3) {
            setLinks([...newLinks, '']);
        }
    };

    const handleChanges = (e) => {
        const selectedFiles = Array.from(e.target.files);
        const newFiles = [...files, ...selectedFiles];

        if (newFiles.length > 6) {
            setFiles(newFiles.slice(0, 6));
        } else {
            setFiles(newFiles);
        }
    }


    const _verifyOtp = async () => {
        setLoading(true)
        setError(null)
        const response = await verifyOTP(otp, order._id)
        const res = await response.json()
        if (response.status === 200) {
            setRefresh(state => !state)
        }
        else {
            setError(res.message)
        }
        setLoading(false)
    }


    const _deliverOrder = async () => {
        setLoading(true)

        setError(null)

        let uploadedImages
        if (files.length > 0){
            uploadedImages = await imageUpload(files);
        
            if (!uploadedImages) {
                setError("Error Uploading Product Images");
                setLoading(false);
                return;
            }}

        const delivered = await deliverOrder({
            orderId: order._id,
            express_thoughts: data.express_thoughts,
            links,
            images: uploadedImages
        })

        const res = await delivered.json()

        if(delivered.status === 200){
            setRefresh(state => !state)
            toast.success(res.message)
            setPopup(false)
        }
        else {
            setError(res.message)
        }

        setLoading(false)
    }


    useEffect(() => {
        let validLinks = links.length > 1 ? links.filter(link => link.length > 1).every(validateURL) : links.every(validateURL)
        if (data.express_thoughts.length >= constraints.express_thoughts.min && validLinks) {
            setActiveBtn(true)
        } else {
            setActiveBtn(false)
        }

    }, [data, links])

    return (
        <div className='w-full left-0 top-0 z-[60] flex justify-center items-center h-screen overflow-hidden fixed bg-black/80'>
            <div className={`${type === 'Store' && !order.otp_verified ? 'w-1/4' : 'w-[40%]'} transition-all bg-white rounded-xl border-[1px] border-themeGrey-70`}>
                <div className='flex justify-between items-center px-6 pb-4 pt-5'>
                    <h2 className='text-themeBlack-300 font-semibold text-lg leading-3'>{t('deliver_order')}</h2>
                    <RxCross1 className='text-themePink text-lg cursor-pointer' onClick={() => setPopup(false)} />
                </div>
                <hr className='w-full h-1 border-t-[0px] border-b-[1px] border-b-themeGrey-100' />
                {type === 'Store' && !order.otp_verified ?
                    <StoreOTP setOtp={setOtp} />
                    :
                    <div className='px-6 py-5 flex flex-col gap-4'>
                        <InputGroup
                            name='express_thoughts'
                            placeholder={t("express")}
                            type={'textarea'}
                            onChange={(e => setData({ ...data, [e.target.name]: e.target.value }))}
                            textAreaSimple={true}
                            flex="flex-col gap-1.5"
                        >
                            <p className='text-xs absolute -top-4 right-0 text-themePink'>{data.express_thoughts?.length}/{constraints.express_thoughts.max}</p>
                        </InputGroup>

                        {links.map((link, index) => (
                            <Input
                                key={index}
                                type='url'
                                value={link}
                                onChange={(e) => handleChange(index, e)}
                                placeholder={t('link')}
                                name={`link-${index}`}
                            />
                        ))}
                        {files.length > 0 && <div className='flex gap-2'>
                            {files.map((file, index) => (
                                <span className='bg-themeGrey-400 rounded-md relative flex flex-col gap-0.5 justify-center items-center px-4 py-4'>
                                    <RxCross1 className='text-themePink text-sm absolute top-2 right-2 cursor-pointer' onClick={() => setFiles(files.filter((_, i) => i !== index))} />
                                    <FaImage className='text-3xl text-themeGrey-300' />
                                    <p className='text-xs text-themeBlack-200'>{file.name.substr(0, 10)}</p>
                                </span>
                            ))}
                        </div>}
                    </div>

                }
               {error && <p className='text-xs px-6 text-center text-red-600 mb-4'>{error}</p>}
                <hr className='w-full h-1 border-t-[0px] border-b-[1px] border-b-themeGrey-100' />
                <div className='flex justify-between px-6 py-2'>
                    <span className='w-1/4 relative'>
                        {((type === 'Store' && order.otp_verified) || type === 'Product') &&
                            <>
                                <input type='file' accept='image/*' onChange={handleChanges} multiple className='absolute opacity-0' id='img' onClick={(e) => e.stopPropagation()} />
                                <SecondaryButton active={files.length < 6 ? true : false} onClick={() => document.getElementById('img').click()} text={t("upload_images")} />
                            </>
                        }
                    </span>
                    <span className='w-1/4'>
                        {(type === 'Store' && !order.otp_verified) ?
                            <Button onClick={_verifyOtp} active={otp?.length === 6} disabled={loading} text={t("verify")} />
                            :
                            <Button onClick={_deliverOrder} active={activeBtn} disabled={loading} text={t("deliver")} />
                        }
                    </span>
                </div>
            </div>
        </div>
    )
}

export default DeliverPopup
