

import React, { createContext } from 'react'


export const Context = createContext()

function LangaugeProvider({ children }) {
    const [siteLang, setSiteLang] = React.useState('kor')

  
  return (
    <Context.Provider value={ { siteLang, setSiteLang } }>
      { children }
    </Context.Provider>
  )
}

export default LangaugeProvider
