import { useEffect, useState } from "react"
import { FaCalendar, FaUser } from "react-icons/fa"
import { Link, useNavigate } from "react-router-dom"
import Button from "../Button"
import Input from "../Input"
import { motion } from 'framer-motion'
import { validateDoB, validateName, validateNumber } from "../../constants/validations"
import { RegisterUser } from "../../services/RegisterUser"

const Section2 = ({ }) => {

    const [activeBtn, setActiveBtn] = useState(false)
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState(JSON.parse(localStorage.getItem('newUser') ?? "{}"))

    const onChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value })
    }

    const navigate = useNavigate()

    const onCLick = async() => {
        setError(null)
        setLoading(true)
        localStorage.setItem('newUser', JSON.stringify(data))
         const response = await RegisterUser(data)
        if (response && response.status) {
            navigate('/signup/verify-email')
        }
        else {
                setError(response.message)
            setLoading(false)
            return
        }
    }

    useEffect(() => {
        if (data.name || data.dob || data.phone) {
            if (validateDoB(data.dob ?? '') && validateName(data.name ?? '')  && validateNumber(data.phone ?? '')) 
                setActiveBtn(true)
        }
        else
            setActiveBtn(false)
    }, [data])


    return (
        <div key="section2"
            className='w-full bg-white rounded-[50px] shadow-sm h-auto sm:h-screen flex justify-center items-center sm:rounded-none sm:shadow-none'>
            <div className='flex justify-center items-start sm:items-center sm:justify-start sm:py-24 flex-col gap-2 py-14 px-20 sm:px-0 relative h-full w-full sm:w-[80%]'>
                 <h1 className='text-[32px] leading-[42px] font-Pretendard font-bold text-themePink text-center w-full sm:text-3xl'>ID</h1>
                <p className='text-themeGrey-300 text-base font-medium text-center w-[90%] sm:text-sm'>Please enter all the details that showing below</p>
                <div className='flex justify-start flex-col items-start w-full gap-5 mt-8 pb-20'>
                    <Input
                        type={"text"}
                        placeholder='Your name'
                        required={true}
                        name={"name"}
                        value={data.name}
                        onChange={onChange}
                        icon={<FaUser className="text-base text-themeGrey-150 mt-1" />}
                    />
                    <Input
                        type={"date"}
                        placeholder='Date of Birth'
                        required={true}
                        name={"dob"}
                        value={data.dob}
                        onChange={onChange}
                        icon={<FaCalendar className="text-base text-themeGrey-150 mt-1" />}
                    />
                    <Input
                        type={"phone"}
                        placeholder='Date of Birth'
                        required={true}
                        name={"phone"}
                        value={data.phone}
                        onChange={onChange}
                    />
                    {error && <p className='text-sm text-center text-red-600 sm:text-xs'>{error}</p>}

                    <Button disabled={loading}  text="Next" onClick={onCLick} active={activeBtn} />

                </div>
                <div className='absolute bottom-5 w-[100%] left-0 px-14 sm:px-0'>
                <p className='text-center  mx-auto flex justify-center items-center gap-1 sm:text-sm text-themeBlack-400'>Already have an account? <Link to="/login" className='text-themePink'>Sign In</Link>.</p>
                </div>
            </div>
        </div>
    )
}

export default Section2