export function getChatTime(utcDateStr) {
    // Get current local time
    const now = new Date();
    
    // Convert the UTC date string to a Date object
    const utcDate = new Date(utcDateStr);

    // Calculate the difference in milliseconds
    const diffInMilliseconds = now - utcDate;
    const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);
    
    if (diffInMinutes < 1) {
        return 'Just now';
    } else if (diffInMinutes < 60) {
        return `${diffInMinutes} minute${diffInMinutes > 1 ? 's' : ''} ago`;
    } else if (diffInHours < 24) {
        return `${diffInHours} hour${diffInHours > 1 ? 's' : ''} ago`;
    } else if (diffInDays < 7) {
        return `${diffInDays} day${diffInDays > 1 ? 's' : ''} ago`;
    } else {
        // Format the date as MM/DD/YYYY in the user's local time
        const formatter = new Intl.DateTimeFormat('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric'
        });
        return formatter.format(utcDate);
    }
}



export const getStartDiff = (utcDateStr) => {

    const now = new Date();
    
    const utcDate = new Date(utcDateStr);

    console.log("hourDiff", now.getHours() );


    if (now <= utcDate) {
        return 'Not Started yet';
    }

    const minutes = now.getMinutes()
    const hours = now.getHours()
    const days = hours / 24


    if(days > 1)
        return `${Math.ceil(days)} day ago`
    else if(hours > 1)
        return `${Math.floor(hours)} hours ago`
    else if(minutes > 1)
        return `${minutes} minutes ago`
    else
        return 'Just now'
}