import React, { useState } from 'react'
import InputGroup from './InputGroup'
import { useTranslation } from 'react-i18next'
import Button from './Button'
import { updateMyProfile } from '../services/updateMyProfile'
import { toast } from 'react-toastify'
import { getMyProfile } from '../services/getMyProfile'
import { validateNickname } from '../constants/validations'

function Nickname({ user }) {
    const [t] = useTranslation("global")
    const [nickname, setNickname] = useState(user?.nickname || "")
    const [loading, setLoading] = useState(false)
    const [nicknameDone, setNicknameDone] = useState(user?.nickname?.length > 0)
    const [error, setError] = useState(null)

    const register = async () => {
        setLoading(true)
        setError(null)
        const update = await updateMyProfile({nickname: nickname}, 'nickname')
        
        if(update === true){
            getMyProfile()
            setNicknameDone(true)
        }
        else{
            setError(update.message)
        }

        setLoading(false)
    }
    return (
        <>
        <div className='flex justify-between items-end gap-3'>
            <InputGroup
                label={t("Nickname")}
                type="text"
                required={true}
                value={nickname}
                name="nickname"
                onChange={(e) => {
                    setNickname(e.target.value)
                }}
                flex="flex-col gap-1.5"
                placeholder={t("Nickname_placeholder")}
                readOnly={false}
                // disabled={nicknameDone}
            >
               {!nicknameDone && <p className='text-xs text-themeGrey-50'>{t("nickname_constraint")}</p>}
            </InputGroup>
            <span className='w-1/4'>
     
                <Button text={t('Check')} active={validateNickname(nickname)} disabled={loading} onClick={register} />
           
            </span>

        </div>
        <p className='text-xs -mt-2 text-themePink font-semibold'>{window.location.origin}/link/{nickname}</p>

        {error && <p className='text-xs text-red-600 -my-2'>{error}</p>}
        </>
    )
}

export default Nickname
