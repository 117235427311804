import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../Button';
import { BASE_URL, CHANNEL_KEY, MERCHANT_ID, STORE_ID } from '../../constants';
import { useNavigate } from 'react-router-dom';
import * as PortOne from "@portone/browser-sdk/v2";
import { Context } from '../../Provider/LangaugeProvider';
import { IoCheckmarkOutline } from "react-icons/io5";
import { RxCross1 } from "react-icons/rx";

function Payment({ campaignData, setActive }) {
  const grand_total = campaignData?.paymentAmount ?? 0
  const { sitelang } = useContext(Context)
  const total = Number((grand_total / 1.1).toFixed(2))
  let vat = Number((total * 0.1).toFixed(2));
  const [t] = useTranslation("global")
  const navigate = useNavigate()

  const [loading, setLoading] = React.useState(false)
  const [activeBtn, setActiveBtn] = React.useState(!campaignData.paymentProccessed)


  const portOne = async () => {
    setLoading(true)
    let config = {
      storeId: STORE_ID,
      merchantId: MERCHANT_ID,
      paymentId: campaignData.paymentId,
      orderName: sitelang === 'eng' ? "Marketing Fee" : "임팩트",
      totalAmount: Math.ceil(campaignData.grand_total),
      payMethod: campaignData.payback ? "VIRTUAL_ACCOUNT" : "CARD",
      currency: "KRW",
      pgProvider: "TOSSPAYMENTS",
      channelKey: CHANNEL_KEY,
    }
    if(campaignData.payback) {
      config.validHours = 1
    }
    if (window.innerWidth < 768)
      config.redirectUrl = `${BASE_URL}/payment`

    try {
      const response = await PortOne.requestPayment(config);

      const responses = await fetch(`${BASE_URL}/payment`, {
        method: 'Post',
        headers: {
          'Content-Type': 'Application/json'
        },
        body: JSON.stringify(response)
      })

      const res = await responses.json()
      if (responses.status === 200) {
        navigate(res.url)
      }

    } catch (error) {
      console.error(error)
      navigate(`/payment-canceled`)
    }
    setLoading(false)
    setActiveBtn(false)
  }

  


  return (
    <div className='flex flex-col gap-0 py-6 px-6 w-[100%] sm:pt-0 sm:pb-20  sm:h-full'>
      <h3 className='text-themeBlack-300 font-semibold text-lg'>{t("paycharges")}</h3>
      <p className='text-sm text-themeGrey-300'>{t('textPaid')}</p>

      <div className='flex w-full flex-col mt-5 gap-1.5'>
        <p className='flex justify-between items-center text-sm text-themeGrey-300'>
          <span className='font-medium'>{t('reels')}</span>
          {
            campaignData.reels ? 
            <IoCheckmarkOutline className='text-themeBlack-300 text-lg'/>
            :
            <RxCross1 className='text-themeBlack-300 text-base'/>
          }
        </p>
        <p className='flex justify-between items-center text-sm text-themeGrey-300'>
          <span className='font-medium'>{t('Shorts')}</span>
          {
            campaignData.shorts ? 
            <IoCheckmarkOutline className='text-themeBlack-300 text-lg'/>
            :
            <RxCross1 className='text-themeBlack-300 text-base'/>
          }
        </p>
        <p className='flex justify-between items-center text-sm text-themeGrey-300'>
          <span className='font-medium'>{t('tiktok')}</span>
          {
            campaignData.tiktok ? 
            <IoCheckmarkOutline className='text-themeBlack-300 text-lg'/>
            :
            <RxCross1 className='text-themeBlack-300 text-base'/>
          }
        </p>
        <p className='flex justify-between items-center text-sm text-themeGrey-300'>
          <span className='font-medium'>{t('creators')}</span>
          <span className='font-medium'>{campaignData.creator_count}</span>
        </p>
        {["Payback", "Review"].includes(campaignData.campaign_option) && <p className='flex justify-between items-center text-sm text-themeGrey-300'>
          <span className='font-medium'>{t('AdmediaFee')}</span>
          <span className='font-medium'>{campaignData.media_fee}</span>
        </p>}
        {campaignData.campaign_type === 'Seeding' && <p className='flex justify-between items-center text-sm text-themeGrey-300'>
          <span className='font-medium'>{t('delivery_fee')}</span>
          <span className='font-medium'>{campaignData.delivery_fee}</span>
        </p>}
        {campaignData.campaign_type === 'Payback' &&
          <>
            <p className='flex justify-between items-center text-sm text-themeGrey-300'>
              <span className='font-medium'>{t('payback')}</span>
              <span className='font-medium'>{campaignData.payback_amount}</span>
            </p>
            <p className='flex justify-between items-center text-sm text-themeGrey-300'>
              <span className='font-medium'>{t('payback_proccessing_tax')}</span>
              <span className='font-medium'>{campaignData.payback_processing_fee}</span>
            </p>
          </>
        }
        {campaignData.campaign_type === 'Action' && <p className='flex justify-between items-center text-sm text-themeGrey-300'>
          <span className='font-medium'>{t('action_option')}</span>
          <span className='font-medium'>{campaignData.action_amount}</span>
        </p>}

        <p className='flex justify-between items-center text-sm text-themeGrey-300 mt-8'>
          <span className='font-medium'>{t('total')}</span>
          <span className='font-medium'>{Number(campaignData.total).toLocaleString()}</span>
        </p>
        <p className='flex justify-between items-center text-sm text-themeGrey-300'>
          <span className='font-medium'>{t('vat')}</span>
          <span className='font-medium'>{Number(campaignData.vat).toLocaleString()}</span>
        </p>
        <p className='flex justify-between items-center text-sm text-themeBlack-300 border-y-[1px] py-2 border-themeGrey-70'>
          <span className='font-bold'>{t('GrandTotal')}</span>
          <span className='font-bold'>{Number(campaignData.grand_total).toLocaleString()}</span>
        </p>
      </div>

      <span className='flex sm:absolute sm:bottom-3 sm:left-0 mt-4 sm:mt-0 justify-end w-full x-[60]'>
        <div className='sm:px-3 w-1/3 sm:w-full '>
          <Button disabled={loading} active={activeBtn} onClick={portOne} text={t('proceed')} />
        </div>
      </span>
    </div>
  )
}

export default Payment
