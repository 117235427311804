import React, { useEffect, useRef, useState } from 'react'
import { IMAGE_URL, PLATFORMS } from '../constants'
import { CiChat1 } from "react-icons/ci";
import { defaultPfp } from '../utils/defaultPfp'
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { convertToSeoulTime } from '../utils/convertToSeoulTime';
import { updateOrderStatus } from '../services/updateOrderStatus';
import { MdCancel, MdPublishedWithChanges } from 'react-icons/md';
import { TbFlagCancel } from "react-icons/tb";
import { FaCheck } from 'react-icons/fa';
import { IoIosArrowDown } from 'react-icons/io';
import { completeOrder } from '../services/completeOrder';
import { formateDate } from '../utils/formateDate';
import { getLevelDetail } from '../utils/getLevelDetail';

function ApplicantHeader({ creator, order, setRefresh, user, setDispute, index }) {
    const navigate = useNavigate()
    const [t] = useTranslation('global')


    return (
        <div className='flex justify-start items-start gap-4 sm:gap-6 sm:justify-between'>
            <div className='w-[10%] flex flex-col justify-center items-center gap-1 sm:gap-2 sm:w-auto'>
                <Link to={`/${creator.nickname}`} className='w-14 h-14 '>
                    <img src={creator.profilePic ? `${IMAGE_URL}/uploads/${creator.profilePic}` : defaultPfp()} className='w-14 h-14 rounded-full bg-themePink/10' />
                </Link>
                {order.creator._id !== user._id && <p
                    onClick={() => {
                        sessionStorage.setItem('chatUser', JSON.stringify({
                            nickname: creator?.nickname,
                            profilePic: creator?.profilePic,
                            _id: creator?._id
                        }))
                        navigate(`/inbox/${creator?.nickname}`)
                    }}
                    className='text-themeBlue w-full hover:bg-themeBlue/50 hover:text-white flex justify-center items-center py-0.5 border-themeBlue border-[1px] rounded-md cursor-pointer'>
                    <CiChat1 className='text-xl' />
                </p>}
            </div>
            <div className='flex justify-between items-start py-1 w-[90%] sm:flex-col sm:w-[75%]'>
                <div className='flex flex-col gap-1 sm:w-full w-1/2'>
                    <div className='flex justify-between items-center'>
                        <p className='flex w-1/2 sm:w-full flex-col text-xs text-[#555555]'>
                            <span className='capitalize'>{creator.nickname}</span>
                            <span>Since: {formateDate(creator.createdAt)}</span>
                        </p>
                        {order.matchingRate > 0 &&
                            <p className='text-[#555555] text-lg font-bold'>
                                {order.matchingRate}%
                            </p>}
                    </div>
                    <div className='flex justify-between items-center'>
                        <div className='flex justify-start items-center gap-1'>
                            <p className='text-[#555555] text-xs px-2 py-1 border-[1px] border-themeGrey-50 rounded-md'>
                                {creator.category?.name}
                            </p>
                            {creator?.socials && <div className='flex flex-col gap-2 rounded-lg justify-center'>
                                {PLATFORMS.filter(platform => creator?.socials[platform.key]).map(platform => (
                                    <a href={creator?.socials[platform.key].account_address} target='_blank' className=''>
                                        <img key={platform.key} src={platform.logo} alt={`${platform.title} logo`} className='w-5 h-5 object-contain' />
                                    </a>
                                ))}
                            </div>}
                        </div>
                        <div className='flex flex-col justify-end'>
                            <p className='text-[#555555] text-xs'>{getLevelDetail(creator.level)}</p>
                        </div>
                    </div>
                    <p className='text-xs text-themePink'>{window.location.origin}/link/{creator.nickname}</p>
                </div>
                <div className='flex justify-between gap-6 items-end flex-col sm:w-full sm:mt-4 relative'>
                    {!['Pending', 'Active', 'Delivered', 'Disputed'].includes(order.status) && order.creator._id !== user._id &&
                        <Menu order={order} setRefresh={setRefresh} setDispute={setDispute} user={user} index={index} />
                    }
                </div>
            </div>
        </div>
    )
}

export default ApplicantHeader



const Menu = ({ order, setRefresh, user, setDispute, index }) => {

    const [t] = useTranslation("global");
    const [menu, setMenu] = useState(false);
    const menuRef = useRef();
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setMenu(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const approveReject = async (status) => {
        if (loading) return
        setLoading(true)
        const updated = await updateOrderStatus(order._id, status)
        if (updated)
            setRefresh(state => !state)
        setLoading(false)
        setMenu(false)
    }

    const _onComPlete = async () => {
        if (loading) return
        const confirm = window.confirm(t('are_you_sure_complete'));
        if (!confirm) return;
        setLoading(true);
        await completeOrder({
            orderId: order._id,
        })
        setRefresh(state => !state)
        setLoading(false);
        setMenu(false)
    }

    const scrollToDispute = () => {
        setDispute(true)
        const orderDivs = document.querySelectorAll('#order');

        const targetDiv = orderDivs[index];
        console.log(targetDiv.scrollHeight);
        if (targetDiv) {
            targetDiv.scrollIntoView(0, targetDiv.scrollHeight);
        }
        setMenu(false)
    }


    return (
        <>
            <button onClick={() => setMenu(true)} className='outline-none border-[1px] flex gap-1 items-center border-themeGrey-70 rounded-md p-1.5'>
                <p className='text-sm text-themeGrey-300 whitespace-nowrap'>{t('manage')}</p>
                <IoIosArrowDown className='flex text-lg text-themeGrey-300 ' />
            </button>
            {menu &&
                <div ref={menuRef} className='absolute w-36 overflow-hidden glass border-[1px] border-themeGrey-100 z-40 shadow-md right-0 rounded-md top-[110%]'>
                    {order.status === 'Pending' && order.creator._id !== user._id && <>
                        <p onClick={() => approveReject('Rejected')} className='z-10 relative py-2 px-4 flex justify-start items-center gap-2 hover:bg-white cursor-pointer text-sm border-b-[1px] border-themeGrey-100 text-themeBlack-300'>
                            <MdCancel className="text-lg text-themeYellow" />
                            {t('reject')}
                        </p>
                        <p onClick={() => approveReject('Active')} className='z-10 relative py-2 px-4 flex justify-start items-center gap-2 hover:bg-white cursor-pointer text-sm border-b-[1px] border-themeGrey-100 text-themeBlack-300'>
                            <FaCheck className="text-base text-themePink" />
                            {t('approve')}
                        </p>
                    </>}
                    {(!['Pending', 'Cancelled', 'Rejected', 'Completed'].includes(order.status)
                    ) && order.creator._id !== user._id && <>
                            <p onClick={_onComPlete} className='z-10 relative py-2 px-4 flex justify-start items-center gap-2 hover:bg-white cursor-pointer text-sm border-b-[1px] border-themeGrey-100 text-themeBlack-300'>
                                <FaCheck className="text-base text-themePink" />
                                {t('complete')}
                            </p>
                            <p onClick={scrollToDispute} className='z-10 relative py-2 px-4 flex justify-start items-center gap-2 hover:bg-white cursor-pointer text-sm border-b-[1px] border-themeGrey-100 text-themeBlack-300'>
                                <MdPublishedWithChanges className="text-lg text-themeYellow" />
                                {t('Modification')}
                            </p>
                            {['Delivered', 'Disputed'].includes(order.status) &&
                                <p onClick={() => approveReject('Cancelled')} className='z-10 relative py-2 px-4 flex justify-start items-center gap-2 hover:bg-white cursor-pointer text-sm border-b-[1px] border-themeGrey-100 text-themeBlack-300'>
                                    <TbFlagCancel className="text-lg text-red-500" />
                                    {t('cancel')}
                                </p>}
                        </>}
                    <div className='absolute bottom-0 w-1/2 h-8 bg-themePink blur-3xl opacity-50 z-0' />
                </div>
            }
        </>
    )
}