import { BASE_URL } from "../constants";
import { getToken } from "../utils/getToken";

export const  getCampaigns = async (query) => {
    
    try {
        const response = await fetch(`${BASE_URL}/campaign?type=${query.type}&category=${query.category}&search=${query.search}&platform=${query.platform}&region=${query.region}&sort=${query.sort}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'token': getToken()
            }
        })
        const res = await response.json()
        return res.campaigns
    } catch (error) {
        console.log(error);
    }
    return []
}