import i18next from 'i18next'
import React, { useEffect, useRef } from 'react'
import { FaRegDotCircle } from 'react-icons/fa'
import { Context } from '../Provider/LangaugeProvider'

function Language( { open, setOpen } ) {
    const { siteLang, setSiteLang } = React.useContext(Context)
    const [language, setLanguage] = React.useState(siteLang)
    const menuRef = React.useRef(null)
    const refhook = useRef()

    const setLang = (lang) => {
        if (lang === 'eng') {
            setLanguage('eng')
            setSiteLang('eng')
        } else {
            setLanguage('kor')
            setSiteLang('kor')
        }

        i18next.changeLanguage(lang, (err, t) => {
            if (err) return console.log('Something went wrong loading the language', err);
        });

        setOpen(false)
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [setOpen]);

  
    // const loadGoogleTranslate = () => {
    //     const script = document.createElement('script');
    //     script.src = 'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
    //     script.async = true;
    //     document.body.appendChild(script);
        
    //     window.googleTranslateElementInit = () => {
    //       new window.google.translate.TranslateElement(
    //         {
    //           pageLanguage: 'en',
    //           includedLanguages: 'en,ko', // English and Korean
    //           layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
    //         },
    //         refhook.current
    //       );
    //     };
    //   };
    
    //   useEffect(() => {
    //     if (!window.google) {
    //       loadGoogleTranslate();
    //     } else {
    //       // If the script is already loaded
    //       new window.google.translate.TranslateElement(
    //         {
    //         //   pageLanguage: 'en',
    //           includedLanguages: 'en,ko', // English and Korean
    //           layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
    //         },
    //         refhook.current
    //       );
    //     }

    //     refhook.current.click()
    //   }, []);
    
    return (
        <div className='relative over'>
            {/* <div ref={refhook} id="google_translate_element" className="bg-[#3F2388] w-5 h-5 right-20 top-5 absolute opacity-0">

            </div> */}

        
            {open && <div ref={menuRef} className='absolute glass top-10 overflow-hidden right-0 w-32 bg-white border-[1px] border-themeGrey-100 rounded-lg shadow-md z-50'>

                <div onClick={() => setLang('eng')} className='px-2 py-2 flex justify-start items-center gap-1.5 hover:bg-white cursor-pointer border-b-[1px] border-themeGrey-150'>
                    <img src={require('../assets/eng.png')} alt='flag' className='w-5 h-5 object-cover' />
                    <p className='text-sm text-themeGrey-300'>English</p>
                    <FaRegDotCircle className={`${language === 'eng' ? 'text-themePink' : 'text-themeBlack-400'} text-sm ml-4`} />
                </div>

                <div onClick={() => setLang('kor')} className='px-2 py-2 flex justify-start items-center gap-1 cursor-pointer hover:bg-white'>
                    <img src={require('../assets/kor.png')} alt='flag' className='w-6 h-6 object-cover rounded-full' />
                    <p className='text-sm text-themeGrey-300'>Korean</p>
                    <FaRegDotCircle className={`${language === 'kor' ? 'text-themePink' : 'text-themeBlack-400'} text-sm ml-4`} />
                </div>
            </div>}
        </div>
    )
}

export default Language
