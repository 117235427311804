import React from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

function ProfileSkeleton() {
    return (
        <SkeletonTheme baseColor="#F3F3F3" highlightColor="#E5E5E5">
            <div className='pb-20 px-60 md:px-16 pt-10 flex justify-center gap-16 md:gap-8 items-start sm:px-6 sm:flex-col sm:pt-4 bg-themeGrey-400/30 min-h-screen'>
                <div className='flex justify-center flex-col w-[40%] gap-4 sm:w-full'>
                    <div className='w-full flex justify-center items-center'>
                        <Skeleton count={1} width={"96px"} height={"96px"} style={{ borderRadius: "100%" }} />
                    </div>
                    <Skeleton count={3} width={"100%"} height={"12px"} style={{ borderRadius: "12px" }} />
                    <Skeleton count={3} width={"100%"} height={"45px"} style={{ borderRadius: "12px" }} />

                </div>


            </div>
        </SkeletonTheme>
    )
}

export default ProfileSkeleton
