import React, { useState, useEffect } from 'react';
import { getCategories } from '../services/getCategories';
import { Context } from '../Provider/LangaugeProvider';



function CategoryCarousel({  scrolled, setQuery, query  }) {
    const [scrollPosition, setScrollPosition] = useState(0);
    const [maxScroll, setMaxScroll] = useState(0);
    const scrollStep = 200; // Amount of pixels to scroll by
    const  { siteLang } = React.useContext(Context)
    const [categories, setCategories] = useState([])

    useEffect(() => {
        const container = document.getElementById('carousel-container');
        setMaxScroll(container.scrollWidth - container.clientWidth);

        const handleScroll = () => {
            setScrollPosition(container.scrollLeft);
        };

        container.addEventListener('scroll', handleScroll);

        return () => {
            container.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleScroll = (direction) => {
        const container = document.getElementById('carousel-container');
        const newScrollPosition =
            direction === 'left' ? scrollPosition - scrollStep : scrollPosition + scrollStep;

        container.scroll({
            left: newScrollPosition,
            behavior: 'smooth',
        });
        setScrollPosition(newScrollPosition);
    };


    useEffect(() => {
        const get = async() => {
            const res = await getCategories(true, siteLang)
            setCategories([{value: '', label: siteLang === 'kor'? '모두': 'All'},  ...res])
        }
        get()
    }, [siteLang])

    return (
        <div className={`relative px-16 sm:px-6  sm:border-b-[1px] sm:-mt-1  sm:border-themeGrey-150  ${scrolled  && `border-b-[1px] border-themeGrey-70`}`}>
            {/* Left arrow button */}
            {scrollPosition > 0 && (
                <button
                    onClick={() => handleScroll('left')}
                    className='sm:hidden absolute left-16 bg-white top-2.5 outline-none transform border-[1px] border-themeGrey-100 rounded-full p-1 px-3.5 text-xl  sm:p-1 sm:text-sm sm:px-3 sm:top-0 sm:left-1'
                >
                    &#8249;
                </button>
            )}

            {/* Carousel Container */}
            <div
                id='carousel-container'
                className=' flex h-full justify-center sm:justify-start sm:gap-6 sm:pb-0.5 sm:px-0 gap-7 relative overflow-y-hidden overflow-x-auto whitespace-nowrap scrollbar-hide'
            >
                {categories.map((category) => (
                    <div
                        onClick={() => {
                            if(query?.category && query?.category === category.value) 
                                setQuery({ ...query, category: '' });
                            else
                            setQuery({ ...query, category: category.value });
                        }}
                        key={category.id}
                        className={`flex cursor-pointer leading-3 sm:text-sm items-center relative justify-center h-16 sm:pb-2 sm:h-auto text-center ${query?.category == category.value? 'text-themePink !text-sm font-bold' :'text-themeBlack-900 sm:font-medium'} text-sm `}
                    >
                        {category.label}

                       {query?.category == category.value && <span className='h-1 w-full absolute left-0 -bottom-0.5 sm:-bottom-1 z-30 bg-themePink'></span>}
                    </div>
                ))}
            </div>

            {/* Right arrow button */}
            {scrollPosition < maxScroll && (
                <button
                    onClick={() => handleScroll('right')}
                    className='sm:hidden absolute right-16 bg-white top-2.5 outline-none transform border-[1px] border-themeGrey-100 rounded-full p-1 px-3.5 text-xl sm:p-1 sm:text-sm sm:px-3 sm:top-0 sm:right-1'
                >
                    &#8250;
                </button>
            )}
        </div>
    );
}

export default CategoryCarousel;
