import { BASE_URL } from "../constants";
import { getToken } from "../utils/getToken";

export const getMyOrdersCreator = async (status, searchText) => {
    try {
        const response = await fetch(`${BASE_URL}/order/getMyCrearorOrders?status=${status}&searchText=${encodeURIComponent(searchText)}`,{
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'token': getToken()
            }
        })
        const res = await response.json()
        if (response.status === 200){
            return res.orders
        }
    } catch (error) {
        console.log(error);
    }
    return []
}