import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Tab from '../Tab'
import { getCategories } from '../../services/getCategories'
import InputGroup from '../InputGroup'
import Select from '../Select'
import Guide from '../Guide'
import Button from '../Button'
import { createUpdateCampaign } from '../../services/createUpdateCampaign'
import { Context } from '../../Provider/LangaugeProvider'
import OpenAI from 'openai'
import { CHATGPT_API_KEY } from '../../constants'
import { imageUpload } from '../../services/ImageUpload'

const openai = new OpenAI({
    apiKey: CHATGPT_API_KEY,
    dangerouslyAllowBrowser: true
});

function Basic({ campaignData, setActive, setCampaignID }) {
    const { siteLang } = React.useContext(Context)
    const [campaign, setCampaign] = useState(campaignData ?? {
        paymentProccessed: false
    })
    const [categories, setCategories] = React.useState([])
    const [t] = useTranslation("global")
    const [activeBtn, setActiveBtn] = useState(false)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const ref = React.useRef()
    const [generateGPT, setGenerateGPT] = useState(false)
    const [slectedCategory, setSlectedCategory] = useState('')

    const readOnly = campaign.paymentProccessed ? localStorage.getItem('IHaveRight') == 'true' ? false : true : false

    const onChange = (e) => {
        setCampaign({
            ...campaign,
            [e.target.name]: e.target.value
        })

    }

    const onSubmit = async () => {
        setLoading(true)
        setError(null)

        let newImagePath = null
        if (!campaign.descriptionImage && campaign.image) {
            newImagePath = await imageUpload(campaign.image)
        }

        if (Array.isArray(newImagePath)) {
            campaign.descriptionImage = newImagePath[0]
        }

        const response = await createUpdateCampaign(campaign, 'Basic')
        const res = await response.json()
        if (response?.status === 200) {
            setCampaignID(res.campaign._id)
            localStorage.setItem('campaign_id', res.campaign._id)
            setActive(2)
        }
        else if (response?.status !== 200) {
            setError(res.message)
        }
        else {
            setError("Some error occured, please try again")
        }

        setLoading(false)
    }

    useEffect(() => {
        const categoryoption = async () => {
            const res = await getCategories(true, siteLang)
            setCategories(res)
            const beautyCategory = res.filter(r => r.key.toLowerCase() === 'beauty')[0]
            console.log(res);

            if (campaignData?.category)
                setCampaign(prevData => ({ ...prevData, category: campaignData.category }))
            else if (beautyCategory) {
                setCampaign(prevData => ({ ...prevData, category: beautyCategory.value }))
            }
        }
        categoryoption()
    }, [])

    useEffect(() => {
        if (campaignData) {
            setCampaign(campaignData)
        }
    }, [campaignData])


    useEffect(() => {
        if (campaign.category && campaign.brand_title && campaign.image && campaign.product_name && campaign.product_url?.length > 0)
            setGenerateGPT(true)
        else
            setGenerateGPT(false)
    }, [campaign])



    const GPT = async () => {
        try {
            setLoading(true)
            let newImagePath = null
            if (campaign.image) {
                newImagePath = await imageUpload(campaign.image)
            }

            if (Array.isArray(newImagePath)) {
                newImagePath = newImagePath[0]
                setCampaign({ ...campaign, descriptionImage: newImagePath[0] })
            }

            const response = await openai.chat.completions.create({
                model: "gpt-4o-mini",
                messages: [
                    {
                        role: "user",
                        content: `
            I'm trying to market the product shown in this image: ${newImagePath}. 
            it's name is ${campaign.product_name} and brand is ${campaign.brand_title} and category is ${slectedCategory}.
            Please provide:
            1. A one-sentence product summary.
            2. Five hashtags.
            3. Five key points to emphasize about the product.
            4. Appropriate subtitles for Instagram Reels, TikTok, and YouTube Shorts.
            5. Tips for creating engaging marketing videos for this product.
            remmber all texts hould be plain text.
            `
                    }
                ],
            });
            setLoading(false)
            const content = response?.choices?.[0]?.message?.content;
            const extractSections = (text) => {
                const regex = /(\d\.)\s([\s\S]*?)(?=\n\d\.|$)/g;
                const sections = {};
                let match;

                while ((match = regex.exec(text)) !== null) {
                    const key = match[1].replace('.', '');
                    const value = match[2].trim();
                    sections[key] = value;
                }
                return sections;
            };

            const sections = extractSections(content);

            const productSummary = sections['1'];
            const hashtags = sections['2'].split(' ');

            const keyPoints = sections['3']
                .split('\n')
                .map(line => line.replace(/^\- /, '').trim())
                .filter(Boolean)
                .join('\n');

            const subtitles = sections['4']
                .split('\n')
                .map(line => line.replace(/^\- /, '').trim())
                .join('\n');

            const tips = sections['5']
                .split('\n')
                .map(line => line.replace(/^\- /, '').trim())
                .filter(Boolean)
                .join('\n');

            console.log("Product Summary:", productSummary);
            console.log("Hashtags:", hashtags);
            console.log("Key Points:", keyPoints);
            console.log("Subtitles:", subtitles);
            console.log("Tips:", tips);

            setCampaign({
                ...campaign,
                product_description: productSummary.replace(/-/g, '').replace(/"/g, ''),
                what_should_be_emphasized: keyPoints.replace(/-/g, '').replace(/"/g, ''),
                subtitles: subtitles.replace(/-/g, '').replace(/"/g, ''),
                tags: hashtags.map(h => (h.replace('#', ''))),
                types_of_submitted_results: tips.replace(/-/g, '').replace(/"/g, '')
            });



        } catch (error) {
            console.error(error)
        }

        
        setGenerateGPT(false)
    }


    useEffect(() => {
        if (campaign.category?.length &&
            campaign.product_name?.length &&
            campaign.brand_title?.length &&
            campaign.product_url?.length &&
            (campaign.image || campaign.descriptionImage) &&
            campaign.product_description?.length &&
            campaign.what_should_be_emphasized?.length &&
            campaign.subtitles?.length &&
            campaign.tags?.length &&
            campaign.types_of_submitted_results?.length
        )
            setActiveBtn(true)
        else
            setActiveBtn(false)
        
    }, [campaign])



    return (
        <div className='flex flex-col gap-5 py-6 sm:pt-0 relative sm:pb-0'>

            <div className='w-[55%] flex flex-col gap-1.5  px-6  sm:w-full'>
                <h3 className='text-base text-themeBlack-300 font-b sm:pb-0old'>{t("Category")}</h3>
                <Select
                    options={categories}
                    name={"category"}
                    onChange={(e) => {
                        const selectedCategory = categories.find(c => c.value === e.target.value)


                        setSlectedCategory(selectedCategory?.key)
                        onChange(e)
                    }}
                    placeholder={t("category_placeholder")}
                    value={campaign?.category?._id ? campaign?.category._id : campaign?.category}
                    simple={true}
                    activeVal={campaign?.category ? true : false}
                    readOnly={readOnly}
                />
            </div>
            <div className='w-[55%] flex flex-col gap-1.5  px-6  sm:w-full'>
                <InputGroup
                    solidLabel={t("brandName")}
                    name='brand_title'
                    type={"text"}
                    flex='flex-col gap-2'
                    placeholder={t("brandName_placeholder")}
                    required={true}
                    readOnly={readOnly}
                    onChange={onChange}
                    value={campaign.brand_title}
                />
            </div>
            <div className='w-[55%] flex flex-col gap-1.5  px-6  sm:w-full'>
                <InputGroup
                    solidLabel={t("profuctName")}
                    name='product_name'
                    type={"text"}
                    flex='flex-col gap-2'
                    placeholder={t("productName_placeholder")}
                    required={true}
                    readOnly={readOnly}
                    onChange={onChange}
                    value={campaign.product_name}
                />
            </div>
            <div className='w-[55%] flex flex-col gap-1.5  px-6  sm:w-full'>
                <InputGroup
                    solidLabel={t("profuctURL")}
                    name='product_url'
                    type={"url"}
                    flex='flex-col gap-2'
                    placeholder={t("profuctURL")}
                    required={true}
                    readOnly={readOnly}
                    onChange={onChange}
                    value={campaign.product_url}
                />
            </div>
            <div className='w-[55%] relative flex flex-col gap-1.5  px-6  sm:w-full'>
                <input ref={ref} type='file'
                    onChange={(e) => setCampaign({ ...campaign, image: e.target.files[0] })}
                    onClick={(e) => e.stopPropagation()}
                    accept='image/*' className='hidden' />
                <h3 className='text-base text-themeBlack-300 font-bold'>{t("description_image")}</h3>
                <button onClick={() => ref.current.click()} className='border-themeGrey-100 outline-none shadow-sm border-[1px] rounded-lg text-themeBlack-300 py-3 text-sm w-full'>
                    {(campaign.image || campaign.descriptionImage) ? t('change') : t('Upload')}
                </button>
                {campaign.image && <p className='text-themeBlack-300 text-xs'>{campaign.image.name}</p>}
            </div>

            <hr className='w-full h-1 border-t-0 border-b-[2px] border-themeGrey-70 shadow-sm' />
            <div className='w-[55%] relative flex flex-col gap-1.5  px-6  sm:w-full'>
                <Button text={t("generateWithAI")} active={generateGPT} disabled={loading} onClick={GPT} />
            </div>
            <div className='relative flex flex-col gap-1.5  px-6  sm:w-full'>
                <InputGroup
                    solidLabel={t("p_desc")}
                    name='product_description'
                    type={"textarea"}
                    flex='flex-col gap-2'
                    placeholder={t("p_desc_place")}
                    required={true}
                    textAreaSimple={true}
                    readOnly={readOnly}
                    onChange={(e) => { setCampaign({ ...campaign, product_description: e.target.value }); }}
                    value={campaign.product_description}
                />
            </div>
            <div className='relative flex flex-col gap-1.5  px-6  sm:w-full'>
                <InputGroup
                    solidLabel={t("what_emphasized")}
                    name='what_should_be_emphasized'
                    type={"textarea"}
                    flex='flex-col gap-2'
                    placeholder={t("what_emphasized_place")}
                    required={true}
                    readOnly={readOnly}
                    textAreaSimple={true}
                    onChange={onChange}
                    value={campaign.what_should_be_emphasized}
                />
            </div>
            <div className='relative flex flex-col gap-1.5  px-6  sm:w-full'>
                <InputGroup
                    solidLabel={t("subtitle")}
                    name='subtitles'
                    type={"textarea"}
                    flex='flex-col gap-2'
                    placeholder={t("subtitle_place")}
                    required={true}
                    readOnly={readOnly}
                    textAreaSimple={true}
                    onChange={onChange}
                    value={campaign.subtitles}
                />
            </div>
            <div className='relative flex flex-col gap-1.5  px-6  sm:w-full'>
                <InputGroup
                    type={'tag'}
                    name="tags"
                    solidLabel={t("Addtags")}
                    onChange={onChange}
                    readOnly={readOnly}
                    value={campaign.tags || []}
                    flex="flex-col gap-1.5"
                />
            </div>
            <div className='relative flex flex-col gap-1.5  px-6  sm:w-full'>
                <InputGroup
                    type={'tag'}
                    name="account_tag"
                    solidLabel={t("account_tag")}
                    onChange={onChange}
                    readOnly={readOnly}
                    value={campaign.account_tag || []}
                    flex="flex-col gap-1.5"
                />
            </div>
            <div className='relative flex flex-col gap-1.5  px-6  sm:w-full'>
                <InputGroup
                    solidLabel={t("Contentcondition")}
                    name='types_of_submitted_results'
                    type={"textarea"}
                    flex='flex-col gap-2'
                    placeholder={t("Contentconditionplacholder")}
                    required={true}
                    readOnly={readOnly}
                    textAreaSimple={true}
                    onChange={onChange}
                    value={campaign.types_of_submitted_results}
                />
            </div>
            {error && <div className='flex justify-end mb-2 sm:justify-center items-center text-red-500 text-sm px-6'>{error}</div>}    
            <div className='sm:mt-0 px-6 flex justify-end sm:p-3  '>
                <div className='sm:w-full w-1/3'>
                <Button text={t("save")} active={campaign.paymentProccessed ? false : activeBtn} disabled={loading} onClick={onSubmit} />
                </div>
            </div>

        </div>
    )
}

export default Basic
