import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { fetchUserProfile } from '../services/fetchUserProfile'
import LandingNav from '../components/LandingNav'
import { IMAGE_URL, PLATFORMS } from '../constants'
import { defaultPfp } from '../utils/defaultPfp'
import { useTranslation } from 'react-i18next'
import { IoChatbubbleOutline } from "react-icons/io5";
import { RiShareForward2Fill } from 'react-icons/ri'
import CampaignCard from '../components/CampaignCard'
import { toast } from 'react-toastify'
import { formateDate } from '../utils/formateDate'
import ReviewCard from '../components/ReviewCard'
import ProfileSkeleton from '../components/ProfileSkeleton'
import Footer from '../components/Footer'
import HomeLayout from '../layouts/HomeLayout'

function Profile() {
    const { name } = useParams()
    const [profile, setProfile] = useState(null)
    const [t] = useTranslation("global")
    const navigate = useNavigate()
    const user = JSON.parse(localStorage.getItem('user'))

    useEffect(() => {
        const getUserProfile = async () => {
            const data = await fetchUserProfile(name)
            setProfile(data)
        }
        getUserProfile()
    }, [name])

    const copyToClipboard = () => {
        const currentLocation = window.location.href;
        const textarea = document.createElement('textarea');
        textarea.value = currentLocation;
        document.body.appendChild(textarea);
        textarea.select();
        try {
            document.execCommand('copy');
            toast.success("Link copied to clipboard!");
        } catch (err) {
            console.error("Failed to copy URL: ", err);
        }
        document.body.removeChild(textarea);
    };


    return (
        <HomeLayout>
            {profile ?
                <div className='pb-20 px-60 md:px-16 pt-10 flex justify-center gap-16 md:gap-8 items-start sm:px-6 sm:flex-col sm:pt-4 bg-themeGrey-400/30 min-h-screen'>
                    <div className='flex flex-col w-[40%] gap-5 sm:w-full'>
                        <div className='flex flex-col gap-1 justify-center items-center'>
                            <img src={profile?.profilePic ? `${IMAGE_URL}/uploads/${profile?.profilePic}` : defaultPfp()} className='w-24 h-24 rounded-full bg-themePink/10 object-cover shadow-lg' />
                            <h2 className='text-themeBlack-200 font-bold text-sm mt-1'>{profile?.name}</h2>
                            <p className='lowercase text-themeGrey-300 text-xs'>@{profile?.nickname}</p>

                            <div className='flex justify-between items-center w-full mt-5'>
                                <p className='text-themeBlack-300 text-sm'>{t('type')}</p>
                                <p className='text-themeBlack-300 text-sm capitalize'>{profile?.role}</p>
                            </div>
                            <div className='flex justify-between items-center  w-full'>
                                <p className='text-themeBlack-300 text-sm'>{t('level')}</p>
                                <p className='text-themeBlack-300 text-sm'>{profile?.level}</p>
                            </div>
                            <div className='flex justify-between items-center  w-full'>
                                <p className='text-themeBlack-300 text-sm'>{t('CTI')}</p>
                                <p className='text-themeBlack-300 text-sm'>{profile?.ctiScore}</p>
                            </div>
                            <div className='flex justify-between items-center w-full'>
                                <p className='text-sm text-themeBlack-300'>{profile.role === 'creator' ? t('TotalCompltedOrders') : t('totalCompletedCampaigns')}</p>
                                <p className='text-sm text-themeBlack-300'>{profile.role === 'creator' ? (profile.totalCompletedOrders || 0) : (profile.totalCompletedCampaigns || 0)}</p>
                            </div>
                            <div className='flex justify-between items-center w-full'>
                                <p className='text-sm text-themeBlack-300'>{t('CreatedOn')}</p>
                                <p className='text-sm text-themeBlack-300'>{formateDate(profile.createdAt || new Date())}</p>
                            </div>
                            <div className='flex gap-2 w-full mt-2'>
                                {(user && user?._id !== profile?._id) &&
                                    <button onClick={() => {
                                        sessionStorage.setItem('chatUser', JSON.stringify({
                                            nickname: profile?.nickname,
                                            profilePic: profile?.profilePic,
                                            _id: profile?._id
                                        }))
                                        navigate(`/inbox/${profile?.nickname}`)
                                    }} className='text-xs text-themeGrey-70 bg-white py-2.5 outline-none border-[1px] border-themeGrey-70 rounded-lg flex-1 flex justify-center items-center'>
                                        <IoChatbubbleOutline className="text-themeGrey-300 text-lg " />
                                    </button>}
                                <button onClick={copyToClipboard} className='text-xs bg-white text-themeGrey-70 py-2.5 outline-none border-[1px] border-themeGrey-70 rounded-lg flex-1 flex justify-center items-center'>
                                    <RiShareForward2Fill className="text-themeGrey-300 text-lg" />
                                </button>
                            </div>
                        </div>

                        {profile?.socials && <div className='flex flex-col gap-2 rounded-lg justify-center'>
                            {PLATFORMS.filter(platform => profile?.socials[platform.key]).map(platform => (
                                <a href={profile?.socials[platform.key].account_address} target='_blank' className='cursor-pointer items-center border-[1px] border-themeGrey-70 flex gap-4 px-6 py-2 bg-white rounded-md shadow-sm hover:shadow-none hover:border-white transition-all ease-in duration-150'>
                                    <img key={platform.key} src={platform.logo} alt={`${platform.title} logo`} className='w-8 h-8 object-contain' />
                                    <p className='text-themeBlack-300 text-base font-medium'>{platform.title}</p>
                                </a>
                            ))}
                        </div>}

                    </div>

           
                </div>
                :
                <ProfileSkeleton />
            }
            <Footer />
        </HomeLayout>
    )
}

export default Profile
