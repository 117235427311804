

import { toast } from "react-toastify"
import { BASE_URL } from "../constants"
import { getToken } from "../utils/getToken"

export const createPassward = async (new_pass) => {
    try {
        let user = {}
        if (window.location.pathname.includes('/change-password'))
            user = JSON.parse(localStorage.getItem('user'))
        else
            user = JSON.parse(localStorage.getItem('newUser'))
        const response = await fetch(`${BASE_URL}/user/createPassword`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                token: getToken()
            },
            body: JSON.stringify({
                userId: user._id,
                new_pass,
            })
        })

        const res = await response.json()
        if (response.status === 200) {
            if (window.location.pathname.includes('/change-password'))
                toast.success('Password changed successfully');
            else
                toast.success('Account created successfully');
            return true;
        }
        else {
            toast.error(res.message);
        }
    } catch (error) {
        console.log(error);
    }

    return false;
}