export function convertToSeoulTime(utcTime) {
    // Create a Date object from the UTC time string
    const date = new Date(utcTime);

    // Convert to Seoul time using toLocaleString with the Asia/Seoul time zone
    const options = {
        timeZone: 'Asia/Seoul',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
    };

    // Return the formatted Seoul time as a string
    return date.toLocaleString('en-US', options).substring(0, 10);
}
