import React, { createContext } from 'react'
import { getMyNotification } from '../services/getMyNotification';


export const Context = createContext()

function NotificationProvider({ children }) {
    const [notifications, setNotifications] = React.useState(JSON.parse(sessionStorage.getItem('notifications') || '[]'))

    const fetchNotifications = async () => {
        const notifications = await getMyNotification();
        if (notifications.length > 0) {
            setNotifications(notifications);
        }
        setTimeout(fetchNotifications, 7000);
    };
    
    // fetchNotifications();
  return (
    <Context.Provider value={ { notifications, setNotifications } }>
      { children }
    </Context.Provider>
  )
}

export default NotificationProvider
