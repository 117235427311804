import { BASE_URL } from "../constants";
import { getToken } from "../utils/getToken";

export const createUpdateCampaign = async(data, type) => {
    try {
        const response = await fetch(`${BASE_URL}/campaign?type=${type}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                token: getToken()
            },
            body: JSON.stringify(data)
        })
    
    return response;
    } catch (error) {
        console.log(error);
    }
}