import React, { useState } from 'react';
import Button from './Button';
import { useTranslation } from 'react-i18next';
import { createDispute } from '../services/createDispute';
import { completeOrder } from '../services/completeOrder';

function CompleteOrDispute({ order, submission, setRefresh }) {
    const [t] = useTranslation('global');
    const [isExpanded, setIsExpanded] = useState(false);
    const [dispute, setDispute] = useState('');
    const [loading, setLoading] = useState(false);

    const toggleAccordion = () => {
        setIsExpanded(!isExpanded);
    };

   

    const _createDispute = async() => {
        setLoading(true);
        await createDispute({
            submmission_id: submission._id,
            orderId: order._id,
            remarks: dispute,
        })
        setRefresh(state => !state)
        setLoading(false);
    }

    return (
        <div className='flex w-full flex-col' id='dispute'>
            <div
                className='flex gap-0 items-center cursor-pointer'
                onClick={toggleAccordion}
            >
                <p className='text-xs font-medium whitespace-nowrap text-themeYellow'>
                    {t('NeedModification')}
                </p>
            </div>

            <div
                className={`transition-max-height duration-500 ease-in-out overflow-hidden max-h-96`} >
                <textarea onChange={(e) => setDispute(e.target.value)} placeholder={t('writeDispute')}
                 className='mt-1 p-4 py-2 text-sm border border-themeYellow rounded-md h-20 w-full text-themeBlack-500 resize-none bg-themeYellow/10 outline-none'>
                </textarea>
            </div>

            <div className='justify-end items-end flex mt-4'>
                <span className='w-1/3 sm:w-full'> 
                    <Button disabled={loading} onClick={_createDispute} active={dispute.length > 3 ? true : false} text={t('createDisp')} theme="yellow"/>
                </span>
            </div>
        </div>
    );
}

export default CompleteOrDispute;
