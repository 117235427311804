import React from 'react'
import { IMAGE_URL } from '../constants'
import { useTranslation } from 'react-i18next'

function OrderSubmission({ submission }) {
    const [showImage, setShowImage] = React.useState(null)
    const [t] = useTranslation("global")
    return (
        <div className='flex flex-col gap-2 mt-3'>
            {submission.express_thoughts?.split('\n').map((item, key) => (
                <p key={key} className='text-xs text-themeBlack-500 font-medium pr-6  sm:pr-3'>{item}</p>
            ))}
            <div className='flex flex-col gap-0.5 pr-6  sm:pr-3'>
                {submission.links?.map((link, index) => (
                    <a href={link} target='_blank' className='w-full text-xs whitespace-nowrap text-themeBlue'>{link}</a>
                ))}
            </div>
            <div className='flex flex-row justify-start items-stretch gap-2 pr-6  sm:pr-3 pl-2 overflow-x-scroll bg-themeGrey-500/50 py-2'>
                {submission.images?.map((file, index) => (
                    <img onClick={() => setShowImage(file)} src={`${IMAGE_URL}/uploads/${file}`} alt='refresh' className='w-28 h-28 sm:w-20 sm:h-20 rounded-md cursor-pointer object-cover' />
                ))}
            </div>
            {showImage && <ImageViewer setShowImage={setShowImage} image={showImage} />}
           {submission.remarks?.length > 1 && <div className='flex gap-1 pr-3 sm:pr-3 justify-end items-start flex-col pl-28 sm:pl-0 sm:flex-col'>
                <p className='text-xs font-medium text-themeYellow'>{t('customer_asked')}</p>
                <textarea readOnly={true} value={submission.remarks}
                    className='p-4 py-2 text-sm border sm:w-full border-transparent rounded-md min-20 max-h-auto w-full text-themeBlack-500 resize-none bg-themeYellow/15 outline-none'>
                </textarea>
            </div>}
        </div>
    )
}

export default OrderSubmission



const ImageViewer = ({ image, setShowImage }) => {
    return (<div className='fixed top-0 left-0 w-full h-screen bg-black/80 px-40 py-20 z-[60] sm:px-6 sm:py-6'>
        <div className='absolute top-0 left-0 w-full h-full' onClick={() => setShowImage(null)}></div>
        <img src={`${IMAGE_URL}/uploads/${image}`} alt='refresh' className='w-full h-full rounded-md object-contain' />
    </div>)
}