





import { BASE_URL } from "../constants";
import { getToken } from "../utils/getToken";

export const getMessages = async (nickname, page) => {
    try {
        const response = await fetch(`${BASE_URL}/chat/messages?nickname=${nickname}&page=${page}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'token': getToken()
            }
        })
        const res = await response.json()
       return res.messages
    } catch (error) {
        console.log(error);
    }
    return []
}