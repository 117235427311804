import React, { useContext, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { IMAGE_URL } from '../constants'
import { defaultPfp } from '../utils/defaultPfp'
import { getChatTime } from '../utils/getChatTime'
import { markAsRead } from '../services/getMyNotification'
import { Context } from '../Provider/NotificationProvider'
import { useNavigate } from 'react-router-dom'

function Notification({ setNotificationPopuo }) {
    const { notifications, setNotifications } = useContext(Context)
    const [t] = useTranslation("global")
    const notiRef = useRef(null)
    const navigate = useNavigate()
    const user = JSON.parse(localStorage.getItem('user') || "{}")

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (notiRef.current && !notiRef.current.contains(event.target)) {
                setNotificationPopuo(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [notiRef]);

    const onClick = async (n) => {
        setNotifications(prevNotifications =>
            prevNotifications.map(notification =>
                notification._id === n._id ? { ...notification, read: true } : notification
            )
        );
        if (n.type === 'Campaign') {
            if (user.role === 'customer' && n.campaign?._id) {
                navigate(`/manage/${n.campaign.campaign_title}/${n.campaign?._id}`)
            }
        }
        if (n.type === 'Order') {
            if (user.role === 'creator' && n.order?._id) {
                navigate(`/orders/hello/${n.order?._id}`)
            }
        }
        if (n.type === 'Message') {
            if (n.from_user?.nickname)
                navigate(`/inbox/${n.from_user.nickname}`)
        }

        markAsRead(n._id)
    }
    return (
        <div ref={notiRef} className={`flex flex-col border-[1px] w-[350px] border-themeGrey-70 bg-white shadow-md z-[60] overflow-hidden rounded-xl absolute top-20 right-20 sm:w-full  sm:right-0 sm:border-none`}>
            <h2 className='text-themeBlack-300 font-bold text-sm px-4 pt-5 pb-3 border-b-[1px] border-themeGrey-70'>{t('notification')}</h2>
            <div className='flex flex-col min-h-40 max-h-72 overflow-y-scroll sm:min-h-[90vh]'>
                {notifications.length === 0 && <p className='text-themeBlack-500 text-xs font-semibold p-4'>{t('no_activity')}</p>}
                {notifications.map((n, index) => {
                    return <div key={index} onClick={() => onClick(n)} className={`flex justify-start items-center gap-2 px-4 py-3 cursor-pointer hover:bg-themeGrey-400/70 ${n.read === false && 'bg-themeGrey-400/50'}`}>
                        <span className='w-14'>
                            <img src={n.from_user?.profilePic ? `${IMAGE_URL}/uploads/${n.from_user.profilePic}` : defaultPfp()} className='rounded-full bg-themePink/15 w-full h-full object-cover' />
                        </span>
                        <div className='flex-col flex w-full'>
                            <div className='flex justify-between items-center w-full'>
                                <h2 className='text-themeBlack-200 font-medium text-xs capitalize'>{n.title}</h2>
                                <p className='text-themeGrey-50 text-[10px] whitespace-nowrap'>{(getChatTime(n.createdAt))}</p>
                            </div>
                            <p className='text-themeGrey-300 text-sm'>{n.message.length > 150 ? `${n.message.length.substr(0, 150)}..` : n.message}</p>
                        </div>
                    </div>
                })}
            </div>
        </div>
    )
}

export default Notification
