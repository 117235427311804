import React from 'react'
import { useTranslation } from 'react-i18next'
import { FaYoutube } from 'react-icons/fa'
import { IoLogoTiktok } from 'react-icons/io5'
import { RiInstagramFill } from 'react-icons/ri'
import { useLocation } from 'react-router-dom'

function Footer() {
    const [t] = useTranslation('global')
    const location = useLocation()
    
    if(location.pathname.includes('/link')) return <></>;
    return (
        <div className='px-36 md:px-20 border-t-[1px] border-themeGrey-70 bg-themeGrey-400/50 sm:px-6 relative'>
            <div className='flex ustify-start items-start gap-32 py-5  sm:flex-col sm:gap-4'>
                <div className='flex flex-col'>
                    <h2 className='text-sm text-themeBlack-300 sm:text-xs font-bold'>{t('businessInfo')}</h2>
                    <p className='text-sm text-themeBlack-300 sm:text-xs  mt-3'>{(t('company'))} </p>
                    <p className='text-sm text-themeBlack-300 sm:text-xs '>{(t('rep'))} </p>
                    <p className='text-sm text-themeBlack-300 sm:text-xs '>{(t('regNo'))}   </p>
                    <p className='text-sm text-themeBlack-300 sm:text-xs '>{(t('mailOrderNo'))} </p>
                    <p className='text-sm text-themeBlack-300 sm:text-xs '>{(t('address'))} : {t('addresss')} </p>

                    <div className='flex gap-2 mt-5'>
                        <a href="https://code365.notion.site/c69a4e3bbbda4ece906d4d1407e366dd?pvs=4" target='_blank' className='cursor-pointer text-themeBlack-300 sm:text-xs text-sm'>{(t('termsOfUse'))} </a>
                        <a className='cursor-pointer text-themeBlack-300 sm:text-xs  text-sm'>|</a>
                        <a href="https://code365.notion.site/2ece263385e348f2b2c095f646a799e5?pvs=4" target="_blank" className='cursor-pointer text-themeBlack-300 sm:text-xs  text-sm'>{(t('privacyPolicy'))} </a>
                        <a className='cursor-pointer text-themeBlack-300 sm:text-xs  text-sm'>|</a>
                        <a href="https://code365.notion.site/136d96432c6c805b8282c5164961a8ac?pvs=4" target="_blank" className='cursor-pointer text-themeBlack-300 sm:text-xs  text-sm'>{(t('Notice'))} </a>
                    </div>
                </div>
                <div className='flex flex-col'>
                    <div className='flex gap-[29px] sm:gap-7 text-sm text-themeBlack-300 sm:text-xs '>
                        <div className='mr-3.5'>{(t('faqs'))} </div>
                        <span className='flex gap-2'>
                            <a href="https://code365.notion.site/FAQ-114d96432c6c80278492fc43846ecf50?pvs=4" target='_blank'>
                                {(t('customer'))}
                            </a>
                            |
                            <a href="https://code365.notion.site/FAQ-114d96432c6c8058aad6e739d5adf7fe?pvs=4" target='_blank'>
                                {(t('creator'))}
                            </a>
                        </span>
                    </div>
                    <div className='flex gap-5 text-sm text-themeBlack-300 sm:text-xs '>
                        <span className='mr-1'>{(t('center'))}</span>
                        <span>manager@code365.one  |  02-6959-6005</span>
                    </div>
                    <div className='flex gap-6 sm:gap-6 text-sm text-themeBlack-300 sm:text-xs '>
                        <span>{(t('affiliate'))}   </span>
                        <span>business@code365.one</span>
                    </div>

                    <div className='flex gap-2 mt-10'>
                        <a>
                            <FaYoutube className='text-themeBlack-300 text-2xl cursor-pointer' />
                        </a>
                        <a>
                            <RiInstagramFill className='text-themeBlack-300 text-2xl cursor-pointer' />
                        </a>
                        <a>
                            <IoLogoTiktok className='text-themeBlack-300 text-xl cursor-pointer' />
                        </a>
                    </div>
                </div>
            </div>
            <hr className='border-t-[2px] border-themeGrey-50 w-full h-0.5 border-dashed' />
            <p className='text-xs text-themeBlack-300 sm:text-xs  mb-4 mt-2'>{(t('disclaimer'))} </p>

            {!localStorage.getItem('user') && <div className='hiiden sm:flex absolute top-5 right-5'>
                <p

                    onClick={() => {
                        localStorage.setItem('loginRole', 'customer');
                        window.location.pathname = '/login'
                    }}

                    className='text-sm text-themeBlack-300 sm:text-xs border-[1px] border-themeGrey-70 px-2 py-1 rounded-md'>
                    {t('c_login')}
                </p>
            </div>}
        </div>
    )
}

export default Footer
