import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { defaultPfp } from '../utils/defaultPfp'
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import Chat from '../components/Chat';
import { fetchChats } from '../services/fetchChats';
import ChatSeleton from '../components/ChatSeleton';
import { IMAGE_URL } from '../constants';
import { getChatTime } from '../utils/getChatTime';
import { IoLockClosedSharp } from 'react-icons/io5';

function Inbox() {
    const [t] = useTranslation("global")
    const [chats, sendChats] = React.useState(null)
    const [refresh, setRefresh] = useState(false)
    const location = useLocation()
    const getRandomColor = () => {
        const colors = ['bg-red-500', 'bg-blue-500', 'bg-green-500', 'bg-yellow-500', 'bg-purple-500', 'bg-pink-500'];
        return colors[Math.floor(Math.random() * colors.length)];
    };

    useEffect(() => {
        const getChats = async () => {
            const data = await fetchChats()
            const receiver = JSON.parse(sessionStorage.getItem('chatUser'))
            console.log("receiver", receiver );
            console.log("data", data[0] );
            
            const hasMatchingReceiver = data.some(chat =>
                chat.receiver._id === receiver?._id
            );

            if (receiver && !hasMatchingReceiver)
                sendChats([{ receiver }, ...data])
            else
                sendChats(data)
        }
        getChats()
    }, [refresh])




    return (
        <div className='flex justify-start items-start -mt-14 pt-14 border-y-[0px] border-x-[1px] bg-[#f5f5f5]  border-[1px] border-themeGrey-70 h-screen'>
            <div className={`flex flex-col w-[33%] border-r-[1px] border-themeGrey-70 h-full bg-white sm:w-full ${window.innerWidth <= 768 && location.pathname !== '/inbox' && 'sm:hidden'}`}>
                <div className='bg-white -mt-0.5 border-b-[1px] border-themeGrey-200 sm:border-none sm:mt-0'>
                    <h2 className='text-xl font-bold text-black px-6 py-5 sm:pt-5 sm:text-center sm:text-lg'>{t("Inbox")}</h2>
                </div>

                <div className='h-full overflow-y-scroll'>
                    {!chats && [...Array(10)].map((_, index) => (
                        <ChatSeleton key={index} />
                    ))}
                    {chats?.length === 0 && <p className='text-xs text-themeGrey-200 m-2 mx-6'>{t("Nochatsyet")}</p>}
                    {chats?.map((chat, index) => (
                        <Link to={`/inbox/${chat.receiver?.nickname}`} key={chat._id} className='flex justify-start items- gap-4 px-6 py-3 border-b-[1px] border-themeGrey-70 cursor-pointer'>
                            {chat.receiver?.profilePic ? <img src={`${IMAGE_URL}/uploads/${chat.receiver?.profilePic}` || defaultPfp()} className='w-12 h-12 rounded-full bg-themeBlue' alt='refresh window' />
                                :
                                <span className={`w-[60px] h-[50px] p-1 rounded-full text-white font-bold flex justify-center items-center text-xl leading-[0px] ${getRandomColor()}`}>
                                    {chat.receiver?.nickname?.charAt(0).toUpperCase()}
                                </span>}
                            <div className='flex flex-col gap-1 pt-1 w-full'>
                                <span className='flex justify-between items-center w-full' >
                                    <h4 className='text-[##313131] text-sm font-bold capitalize'>{chat.receiver?.nickname}</h4>
                                    {chat.lastMessage && <p className='text-xs text-[#ACB1C0]'>{getChatTime(chat.lastMessage?.createdAt)}</p>}
                                </span>
                                <p className='text-sm text-[#ACB1C0] whitespace-nowrap'>
                                    {chat.lastMessage?.content?.length > 32
                                        ? `${chat.lastMessage.content.substr(0, 32)}...`
                                        : chat.lastMessage?.content || ''}
                                </p>

                            </div>
                        </Link>
                    ))}
                </div>
            </div>


            <div className={`w-[77%] -mt-0.5 overflow-hidden h-full sm:h-screen sm:w-full  ${window.innerWidth <= 768 && location.pathname === '/inbox' && 'sm:hidden'} `}>
                <Routes>
                    <Route index element={
                        <div className='flex flex-col justify-center h-full items-center gap-3'>
                        <IoLockClosedSharp className='text-themeGrey-50 text-5xl' />
                        <p className='text-sm font-medium text-themeGrey-50'>{t('open_chat')}</p>
                    </div>
                    } />
                    <Route path='/:nickname' element={<Chat refresh={refresh} setRefresh={setRefresh} />} />
                </Routes>
            </div>
        </div>
    )
}

export default Inbox