import { BASE_URL } from "../constants";
import { getToken } from "../utils/getToken";

export const getMyProfile = async () => {
    try {
        const response = await fetch(`${BASE_URL}/user/getMyProfile`,{
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'token': getToken()
            }
        })
        const res = await response.json()
        if (response.status === 200){
            localStorage.removeItem('user')
            localStorage.setItem('user', JSON.stringify(res.user))
            return res.user
        }
    } catch (error) {
        console.log(error);
    }
    return null
}