import { toast } from "react-toastify";
import { BASE_URL } from "../constants";
import { getToken } from "../utils/getToken";

export const cancelCampaign = async (id, refunded, paymentId) => {
    try {
        const response = await fetch(`${BASE_URL}/campaign/cancelCampaign/${id}`,{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'token': getToken()
            },
            body: JSON.stringify({
                paymentId: paymentId,
                refundedAmount: refunded
            })
        })
        const res = await response.json()
        if (response.status === 200){
            toast.success(res.message)
            return true
        }
        else{
            toast.error(res.message)
        }
    } catch (error) {
        console.log(error);
    }
    return false
}