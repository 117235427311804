import React from 'react'
import { useTranslation } from 'react-i18next'
import { RiVerifiedBadgeLine } from 'react-icons/ri'

function ProfileTabs({ setActive, active, basicDone, infoDone, socialDone }) {
    const [t] = useTranslation("global")

    const click = (val) => {
        setActive(val)
    }
  return (
    <div className='flex justify-start items-start gap-0 sm:gap-4 sm:rounded-none w-full shadow-sm sm:shadow-none shadow-themeGrey-500 rounded-lg overflow-hidden'>
        {<button onClick={()=>click(0)} className={`flex justify-between items-center whitespace-nowrap px-3 sm:rounded-lg pt-2 pb-2 text-sm border-b-[0px] ${active === 0 ? 'border-themePink text-white bg-themePink' : 'border-themeGrey-150 text-themeBlack-500 bg-themeGrey-600 '} flex-1 outline-none transition-all ease-in duration-0`}>
            {t("basic")}
            {basicDone && <RiVerifiedBadgeLine className='text-themeGreen-500 text-lg' />}
        </button>}
        <button onClick={()=>click(1)} className={`flex justify-between items-center whitespace-nowrap px-3 pt-2 pb-2 sm:rounded-lg sm:border-none text-sm border-b-[0px] ${active === 1 ? 'border-themePink text-white bg-themePink' : 'border-themeGrey-150 text-themeBlack-500 bg-themeGrey-600  border-x-[1px]'} flex-1 outline-none transition-all ease-in duration-0`}>
            {t("info")}
            {infoDone && <RiVerifiedBadgeLine className='text-themeGreen-500 text-lg' />}
        </button>
        {<button onClick={()=>click(2)} className={`flex justify-between items-center whitespace-nowrap px-3 sm:rounded-lg pt-2 pb-2 text-sm border-b-[0px] ${active === 2 ? 'border-themePink text-white bg-themePink' : 'border-themeGrey-150 text-themeBlack-500 bg-themeGrey-600 '} flex-1 outline-none transition-all ease-in duration-0`}>
            {t("social")}
            {socialDone && <RiVerifiedBadgeLine className='text-themeGreen-500 text-lg' />}
        </button>}
    </div>
  )
}

export default ProfileTabs
