import React, { useContext, useEffect, useState } from 'react';
import Guide from '../Guide';
import Select from '../Select';
import { useTranslation } from 'react-i18next';
import { FaEquals } from 'react-icons/fa';
import { FaXmark } from 'react-icons/fa6';
import { getLevels } from '../../services/getLevels';
import Tab from '../Tab';
import { BASE_URL, CHANNEL_KEY, MERCHANT_ID, PLATFORMS, STORE_ID } from '../../constants';
import Button from '../Button';
import { createUpdateCampaign } from '../../services/createUpdateCampaign';
import { useNavigate } from 'react-router-dom';
import { Context } from '../..//Provider/LangaugeProvider'
import InputGroup from '../InputGroup';
import moment from 'moment-timezone';
import Input from '../Input';
import Scheduling from './Scheduling';


function Media({ campaignData, setActive }) {
    const [activeBtn, setActiveBtn] = useState(false);
    const [loading, setLoading] = useState(false);
    const [t] = useTranslation("global");
    const [data, setData] = useState({...campaignData, 
        seeding: campaignData.campaign_type === 'Seeding' ? true : false,
        payback: campaignData.campaign_type === 'Payback' ? true : false,
        action: campaignData.campaign_type === 'Action' ? true : false,
    });
    const [selectedLevel, setSelectedLevel] = useState({});
    const [amounts, setAmounts] = useState({});
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchLevels = async () => {
            const levels = await getLevels(false);
            let options = levels.map(l => ({ ...l, value: l.name, label: l.name }));
            setSelectedLevel(options[1]);
        };
        fetchLevels();
    }, []);


    useEffect(() => {
        if (selectedLevel.youtube_shorts_amount) {
            let total = 0
            let payback_processing_fee = 0
            let media_fee = 0
            let delivery_fee = 0
            if (data.shorts) {
                media_fee += Number(selectedLevel.youtube_shorts_amount)
                total += Number(selectedLevel.youtube_shorts_amount)
            }
            if (data.reels) {
                media_fee += Number(selectedLevel.instagram_reels_amount)
                total += Number(selectedLevel.instagram_reels_amount)
            }
            if (data.tiktok) {
                media_fee += Number(selectedLevel.tiktok_amount)
                total += Number(selectedLevel.tiktok_amount)
            }

            if (data.payback && Number(data.payback_amount) > 0) {
                payback_processing_fee = (Number(data.payback_amount * 0.05) * Number(data.creator_count))
                total += (Number(data.payback_amount) * Number(data.creator_count))
            }
            if (data.action && Number(data.action_amount) > 0) {
                total += (Number(data.action_amount) * Number(data.creator_count))
            }
            if(data.seeding) {
                delivery_fee = Number(selectedLevel.delivery_amount) * Number(data.creator_count)
            }

            let percreator = (total * 0.7) / Number(data.creator_count)
            total += delivery_fee
            total += payback_processing_fee
            let vat = total * 0.1
            let grandTotal = total + vat

            setAmounts({
                media_fee: media_fee,
                payback_processing_fee: payback_processing_fee,
                total: total,
                vat: vat,
                delivery_fee,
                grandTotal: grandTotal,
                percreator: percreator
            })

        }

    }, [data, selectedLevel])


    useEffect(() => {
        setData(prev => ({ ...prev, creator_count: data.action ? 50 : 5 }));
    }, [data.action])


    const readOnly = campaignData?.paymentProccessed ? localStorage.getItem('IHaveRight') == 'true' ? false : true : false

    const onClick = async () => {
        try {
            setError(null)

            const response = await createUpdateCampaign({
                ...data, 
                media_fee: amounts.media_fee,
                total: amounts.total,
                vat: amounts.vat,
                delivery_fee: amounts.delivery_fee,
                grand_total: amounts.grandTotal,
                payback_processing_fee: amounts.payback_processing_fee,
            }, 'Media')
            const res = await response.json()
            if (response?.status === 200) {
                setActive(3)
            }
            else if (response?.status !== 200) {
                setError(res.message)
            }
            else {
                setError("Some error occured, please try again")
            }
        } catch (error) {

        }
    }

    useEffect(() => {
        if (amounts.total > 0) {
            if ((data.shorts || data.reels || data.tiktok) &&
                data.creator_count > 0 &&
                new Date(data.start_date) > new Date() &&
                data.lang

            ) {
                if (data.payback && data.payback_amount > 0 && data.how_to_purchase) {
                    setActiveBtn(true)
                }
                else if (data.action && data.action_amount > 0 && data.what_action_to_take) {
                    setActiveBtn(true)
                }
                else if (data.seeding && data.product_name_2) {
                    setActiveBtn(true)
                }
                else {
                    setActiveBtn(false)
                }
            }

            else {
                setActiveBtn(false)
            }

        }
    }, [data, amounts])

    return (
        <div className=''>

            <div className='px-6 flex flex-col gap-5 py-6 sm:pt-0 sm:pb-18 relative'>
                <h3 className='text-themeBlack-300 font-semibold text-lg sm:text-sm'>{t("method")}</h3>
                <div className='w-[55%] sm:w-full'>
                    <Guide text={t("methodGuide")} />
                </div>
                <div className='flex flex-col gap-2'>
                    <div className='flex justify-start gap-4 items-center '>
                        <input type='checkbox' className='checkbox' onChange={() => setData({
                            ...data,
                            seeding: !data.seeding,
                            payback: false,
                            payback_amount: 0,
                            how_to_purchase: '',
                            action_amount: 0,
                            action: false,
                            what_action_to_take: '',
                        })} checked={data.seeding} />
                        <p className='font-semibold text-themeBlack-300 text-sm whitespace-nowrap'>{t('seeding')}</p>
                    </div>
                    {data.seeding && <InputGroup
                        label={t("profuctName")}
                        name='product_name_2'
                        type={"text"}
                        flex='flex-col gap-1'
                        placeholder={t("productName_placeholder")}
                        required={true}
                        readOnly={readOnly}
                        onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })}
                        value={data.product_name_2}
                    />}
                </div>
                <div className='flex flex-col gap-2'>
                    <div className='flex justify-between'>
                        <div className='flex justify-start gap-4 items-center '>
                            <input type='checkbox' className='checkbox' onChange={() => {
                                setData({
                                    ...data,
                                    payback: !data.payback,
                                    seeding: false,
                                    action: false,
                                    action_amount: 0,
                                    what_action_to_take: '',
                                })
                            }} checked={data.payback} />
                            <p className='font-semibold text-themeBlack-300 text-sm whitespace-nowrap'>{t('Payback')}</p>
                        </div>
                        {data.payback &&
                            <input
                                type='number'
                                name='payback_amount'
                                readOnly={readOnly}
                                onChange={(e) => {
                                    let value = parseInt(e.target.value);
                                    if (value < 0 || isNaN(value)) {
                                        value = 0
                                    }
                                    setData({
                                        ...data,
                                        payback_amount: value
                                    })
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === '-' || e.key === 'e') e.preventDefault();
                                }}
                                value={data.payback_amount}
                                className='w-full text-center min-w-[50px] max-w-[100px] px-6 py-2 sm:px-1 border-themeGrey-100 shadow-sm border-[1px] rounded-md text-sm outline-none text-themeBlack-300'
                            />}
                    </div>
                    {data.payback && <InputGroup
                        label={t("how_to_purchase")}
                        name='how_to_purchase'
                        type={"text"}
                        flex='flex-col gap-1'
                        placeholder={t("how_to_purchase_placeholder")}
                        required={true}
                        readOnly={readOnly}
                        onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })}
                        value={data.how_to_purchase}
                    />}
                </div>
                <div className='flex flex-col gap-2'>
                    <div className='flex justify-between'>
                        <div className='flex justify-start gap-4 items-center '>
                            <input type='checkbox' className='checkbox' onChange={() => setData({
                                ...data,
                                action: !data.action,
                                seeding: false,
                                payback: false,
                                payback_amount: 0,
                                how_to_purchase: '',
                            })} checked={data.action} />
                            <p className='font-semibold text-themeBlack-300 text-sm whitespace-nowrap'>{t('action_option')}</p>
                        </div>
                        {data.action &&
                            <input
                                type='number'
                                name='action_amount'
                                readOnly={readOnly}
                                onChange={(e) => {
                                    let value = parseInt(e.target.value);
                                    if (value < 0 || isNaN(value)) {
                                        value = 0
                                    }
                                    setData({
                                        ...data,
                                        action_amount: value
                                    })
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === '-' || e.key === 'e') e.preventDefault();
                                }}
                                value={data.action_amount}
                                className='w-full text-center min-w-[50px] max-w-[100px] px-6 py-2 sm:px-1 border-themeGrey-100 shadow-sm border-[1px] rounded-md text-sm outline-none text-themeBlack-300'
                            />}
                    </div>

                    {data.action && <InputGroup
                        label={t("whatAction")}
                        name='what_action_to_take'
                        type={"text"}
                        flex='flex-col gap-1'
                        placeholder={t("what_Action_to_take_placeholder")}
                        required={true}
                        readOnly={readOnly}
                        onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })}
                        value={data.what_action_to_take}
                    />}
                </div>
            </div>

            <hr className='w-full h-1 border-t-0 border-b-[2px] border-themeGrey-70 shadow-sm' />

            <div className='px-6 flex flex-col gap-5 py-6 sm:pb-18 relative'>
                <div className='flex justify-between items-center'>
                    <h3 className='text-themeBlack-300 font-semibold text-lg sm:text-sm'>{t("Media")}</h3>
                    <div className='flex justify-start gap-4 items-center '>
                        <input type='checkbox' className='checkbox' onChange={() => setData({ ...data, contest: !data.contest })} checked={data.contest} />
                        <p className='font-semibold text-themeBlack-300 text-sm whitespace-nowrap'>{t('Contest')}</p>
                    </div>
                </div>
                <div className='w-[55%] sm:w-full'>
                    <Guide text={t("mediaGuide")} />
                </div>
                <div className='flex justify-between items-center'>
                    <div className='flex justify-start gap-4 items-center '>
                        <input type='checkbox' className='checkbox' onChange={() => {
                            setData({
                                ...data,
                                shorts: !data.shorts,
                            })
                        }} checked={data.shorts} />
                        <p className='font-semibold text-themeBlack-300 text-sm whitespace-nowrap'>{t('Shorts')}</p>
                    </div>
                    <p className='font-semibold text-themeBlack-300 text-sm whitespace-nowrap'>{selectedLevel?.youtube_shorts_amount ?? 0}</p>
                </div>
                <div className='flex justify-between items-center'>
                    <div className='flex justify-start gap-4 items-center '>
                        <input type='checkbox' className='checkbox' onChange={() => {
                            setData({
                                ...data,
                                reels: !data.reels,
                            })
                        }} checked={data.reels} />
                        <p className='font-semibold text-themeBlack-300 text-sm whitespace-nowrap'>{t('reels')}</p>
                    </div>
                    <p className='font-semibold text-themeBlack-300 text-sm whitespace-nowrap'>{selectedLevel?.instagram_reels_amount ?? 0}</p>
                </div>
                <div className='flex justify-between items-center'>
                    <div className='flex justify-start gap-4 items-center '>
                        <input type='checkbox' className='checkbox' onChange={() => {
                            setData({
                                ...data,
                                tiktok: !data.tiktok,
                            })
                        }} checked={data.tiktok} />
                        <p className='font-semibold text-themeBlack-300 text-sm whitespace-nowrap'>{t('tiktok')}</p>
                    </div>
                    <p className='font-semibold text-themeBlack-300 text-sm whitespace-nowrap'>{selectedLevel?.tiktok_amount ?? 0}</p>
                </div>
            </div>

            <hr className='w-full h-1 border-t-0 border-b-[2px] border-themeGrey-70 shadow-sm' />

            <div className='px-6 flex flex-col gap-5 py-6 sm:pb-18 relative'>
                <h3 className='text-themeBlack-300 font-semibold text-lg sm:text-sm'>{t("language")}</h3>

                <div className='w-[55%] sm:w-full'>
                    <Guide text={t("languageGuide")} />
                </div>
                <div className='flex justify-start gap-4 items-center '>
                    <input type='checkbox' className='checkbox' onChange={() => setData({ ...data, lang: 'korean' })} checked={data.lang === 'korean'} />
                    <p className='font-semibold text-themeBlack-300 text-sm whitespace-nowrap'>{t('korean')}</p>
                </div>
                <div className='flex justify-start gap-4 items-center '>
                    <input type='checkbox' className='checkbox' onChange={() => setData({ ...data, lang: 'japanese' })} checked={data.lang === 'japanese'} />
                    <p className='font-semibold text-themeBlack-300 text-sm whitespace-nowrap'>{t('japanese')}</p>
                </div>
                <div className='flex justify-start gap-4 items-center '>
                    <input type='checkbox' className='checkbox' onChange={() => setData({ ...data, lang: 'chinese' })} checked={data.lang === 'chinese'} />
                    <p className='font-semibold text-themeBlack-300 text-sm whitespace-nowrap'>{t('chinese')}</p>
                </div>
                <div className='flex justify-start gap-4 items-center '>
                    <input type='checkbox' className='checkbox' onChange={() => setData({ ...data, lang: 'english' })} checked={data.lang === 'english'} />
                    <p className='font-semibold text-themeBlack-300 text-sm whitespace-nowrap'>{t('english')}</p>
                </div>
            </div>

            <hr className='w-full h-1 border-t-0 border-b-[2px] border-themeGrey-70 shadow-sm' />

            <div className='px-6 flex flex-col gap-5 py-6 sm:pb-18 relative'>
                <h3 className='text-themeBlack-300 font-semibold text-lg sm:text-sm'>{t("startDate")}</h3>

                <div className='w-[55%] sm:w-full'>
                    <Guide text={t("startDateGuide")} />
                </div>
                <div className='w-[55%] sm:w-full flex flex-col gap-5'>
                    <Input
                        name='start_date'
                        type={"date"}
                        flex='flex-col gap-2'
                        required={true}
                        readOnly={readOnly}
                        textAreaSimple={true}
                        onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })}
                        value={moment(data.start_date).tz('Asia/Seoul').format()?.substr(0, 10)}
                    />
                    <Scheduling campaign={data} />
                </div>
            </div>

            <hr className='w-full h-1 border-t-0 border-b-[2px] border-themeGrey-70 shadow-sm' />

            <div className='px-6 flex flex-col justify-end items-end gap-5 py-6 sm:pb-18 relative'>
                <div className='w-[60%] sm:w-full flex justify-end items-stretch'>
                    <div className='flex flex-col gap-1 justify-between sm:items-start items-end w-[50%]'>
                        <p className='text-sm text-themeBlack-300'>{t('Percreator')}:</p>
                        <p className='text-sm text-themeBlack-300'>{t('creator_count')}:</p>
                    </div>
                    <div className='flex flex-col gap-2 justify-between items-end w-[50%]'>
                        <p className='text-sm text-themeBlack-300'>{amounts.percreator?.toFixed(0) || 0}</p>
                        <input
                            type='number'
                            name='creator_count'
                            readOnly={readOnly}
                            onChange={(e) => {
                                let value = parseInt(e.target.value);
                                if (value < 0 || isNaN(value)) {
                                    value = data.action ? 50 : 5
                                }
                                if (data.action && value < 50)
                                    value = 50
                                else if (value < 5)
                                    value = 5

                                setData({
                                    ...data,
                                    creator_count: value
                                })
                            }}
                            onKeyDown={(e) => {
                                if (e.key === '-' || e.key === 'e') e.preventDefault();
                            }}
                            value={data.creator_count}
                            className='w-full text-center min-w-[80px] max-w-[100px] px-6 py-2 sm:px-1 border-themeGrey-100 shadow-sm border-[1px] rounded-md text-sm outline-none text-themeBlack-300'
                        />
                    </div>
                </div>
            </div>
            <hr className='w-full h-1 border-t-0 border-b-[2px] border-themeGrey-70 shadow-sm' />

            <div className='px-6 flex flex-col justify-end items-end gap-5 py-6 sm:pt-0 sm:pb-18 relative'>
                <div className='w-[60%] sm:w-full flex justify-end items-stretch gap-0'>
                    <div className='flex flex-col gap-1 justify-between sm:items-start items-end w-[50%]'>
                        <p className='text-sm text-themeBlack-300'>{t('sub_total')}:</p>
                        {data.payback && <p className='text-sm text-themeBlack-300'>{t('payback_proccessing_tax')}:</p>}
                        <p className='text-sm text-themeBlack-300'>VAT:</p>
                        <p className='text-sm text-themeBlack-300'>{t('total')}:</p>
                    </div>
                    <div className='flex flex-col gap-2 justify-between items-end w-[50%]'>

                        <p className='text-sm text-themeBlack-300'>{amounts.total || 0}</p>
                        {data.payback && <p className='text-sm text-themeBlack-300'>{amounts.payback_processing_fee || 0}</p>}
                        <p className='text-sm text-themeBlack-300'>{amounts.vat || 0}</p>
                        <p className='text-sm text-themeBlack-300'>{amounts.grandTotal}</p>
                    </div>
                </div>
            </div>
             {error && <div className='flex justify-end mb-2 sm:justify-center items-center text-red-500 text-sm px-6'>{error}</div>}               
            <div className='flex justify-end sm:p-3 px-6 pb-6'>
                <div className='sm:w-full w-1/3'>
                    <Button active={data.paymentProccessed ? false : activeBtn} disabled={loading} onClick={onClick} text={t('save')} />

                </div>
            </div>
        </div>
    );
}

export default Media;

//
//
//