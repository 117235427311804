import React from 'react'
import { useTranslation } from 'react-i18next'
import { IoIosLogOut } from 'react-icons/io'
import { Link, NavLink, useLocation } from 'react-router-dom'

function MobileTopHeader({ user }) {
    const [t] = useTranslation('global')
    const location = useLocation()
    return (
        <div className='hidden sm:flex px-6 pt-5 mb-4 justify-between items-center'>
            <p className='text-lg font-bold text-themeBlack-300 capitalize'>{user.nickname}</p>
            <div className='flex justify-end items-center gap-7'>

                <div className='flex gap-2 justify-between items-center'>
                    <Link to='/profile/my-page' className={`${location.pathname.includes('my-page') && 'font-bold'}  text-themeBlack-300 text-base`}>{t('MyPage')}</Link>
                    <hr className='w-[1px] h-5 bg-themeBlack-300'/>
                    <Link to='/wallet' className={`${location.pathname.includes('wallet') && 'font-bold'}  text-themeBlack-300 text-base`}>{t('wallet')}</Link>
                </div>

            <span
                onClick={() => {
                    localStorage.removeItem('token')
                    localStorage.removeItem('user')
                    localStorage.removeItem('IHaveRight')
                    window.location.href = '/'
                }}>
                <IoIosLogOut className='text-themeBlack-300 text-2xl' />
            </span>
            </div>
        </div>
    )
}

export default MobileTopHeader
