import { BASE_URL } from "../constants";
import { getToken } from "../utils/getToken";

export const getCategories = async (options = false, siteLang) => {
    
    let _options = []
    try {
        const response = await fetch(`${BASE_URL}/category`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'token': getToken()
            }
        })
        const res = await response.json()
        if (response.status === 200) {
            if (options) {
                for (let i = 0; i < res.categories.length; i++) {
                    _options.push({
                        value: res.categories[i]._id,
                        key: res.categories[i].name,
                        label: siteLang === 'kor'?  res.categories[i].kor_name : res.categories[i].name
                    })
                }
                return _options;
            }
            return res.categories
        }
    } catch (error) {
        console.log(error);
    }
    return _options
}