export const getLevelDetail = (level) => {
    switch (level) {
        case 'Entry':
            return '0'
        case 'Level 0':
            return '1 - 20'
        case 'Level 1':
            return '20 - 50'
        case 'Level 2':
            return '50 - 100'
        case 'Pro':
            return '100 - 200'
        default:
            return '200+'
    }
}