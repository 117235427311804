import React from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

function CampDetailSekelton() {
    return (
        <SkeletonTheme baseColor="#F3F3F3" highlightColor="#E5E5E5">


            <div className='px-60 md:px-20 py-5 flex flex-col gap-4 sm:px-0 sm:pb-0 sm:pt-0'>
                <div className='flex justify-between items-start gap-10 sm:flex-col sm:gap-4' >
                    <div className='flex flex-col gap-4 w-[70%] sm:w-full sm:flex-col-reverse'>
                        <div className='flex justify-between items-center sm:px-6'>
                            <Skeleton count={1} width={"200px"} height={"12px"} style={{ borderRadius: "12px" }} />
                            <span className='flex justify-end items-center gap-2'>
                                <span className=''>
                                    <Skeleton count={1} width={"100px"} height={"12px"} style={{ borderRadius: "12px" }} />
                                </span>
                                <Skeleton count={1} width={"25px"} height={"25px"} style={{ borderRadius: "12px" }} />
                            </span>
                        </div>
                        <div className='overflow-hidden'>
                            <Skeleton count={1} width={"100%"} height={"550px"} style={{ borderRadius: "12px" }} />
                        </div>

                    </div>

                    <div className='flex flex-col gap-5 w-[30%] sm:w-full mt-5 sm:mt-0'>
                            <Skeleton count={1} width={"100%"} height={"300px"} style={{ borderRadius: "12px" }} />
                    </div>
                </div>

            </div>

            <div className='px-60 md:px-20 flex justify-between items-start gap-16 sm:px-6'>
                <div className='flex flex-col gap-4 w-[70%] sm:w-full'>

                    <span className='flex justify-start items-center gap-4 py-4 border-b-[1px] border-themeGrey-70'>
                            <Skeleton count={1} width={"50px"} height={"50px"} style={{ borderRadius: "100%" }} />
                        
                        <span className='flex flex-col gap-1'>
                            <Skeleton count={1} width={"150px"} height={"12"} style={{ borderRadius: "12px" }} />
                            <Skeleton count={1} width={"100px"} height={"10"} style={{ borderRadius: "12px" }} />
                           
                        </span>
                    </span>

                    <p className='text-[13px] text-themeBlack-200 leading-[18px]'>
                            <Skeleton count={1} width={"100%"} height={"10"} style={{ borderRadius: "12px" }} />
                            <Skeleton count={1} width={"100%"} height={"10"} style={{ borderRadius: "12px" }} />
                            <Skeleton count={1} width={"100%"} height={"10"} style={{ borderRadius: "12px" }} />
                    </p>
                    <p className='text-[13px] text-themeBlack-200 mt-10 leading-[18px]'>
                            <Skeleton count={1} width={"100%"} height={"10"} style={{ borderRadius: "12px" }} />
                            <Skeleton count={1} width={"100%"} height={"10"} style={{ borderRadius: "12px" }} />
                            <Skeleton count={1} width={"100%"} height={"10"} style={{ borderRadius: "12px" }} />
                    </p>

                    
                </div>

            </div>


            {/* <div className='px-60 md:px-20 flex justify-between items-start gap-10 mt-4 sm:mt-2 sm:px-6 sm:flex-col'>
                <div className='flex flex-col gap-4 w-[70%] sm:w-full'>
                    <div className='flex flex-col gap-0 mt-3'>
                        <p className='text-sm text-[#222] font-semibold'>{t('what_to_do')}</p>
                        <p className='text-[13px] text-themeBlack-200 leading-[18px]'>
                            {campaign?.what_to_do?.split('\n').map((line, index) => (
                                <p key={index}>{line}</p>
                            ))}
                        </p>
                    </div>
                    <div className='flex flex-col gap-0 mt-3'>
                        <p className='text-sm text-[#222] font-semibold'>{t('what_emphasized')}</p>
                        <p className='text-[13px] text-themeBlack-200 leading-[18px]'>
                            {campaign?.what_should_be_emphasized?.split('\n').map((line, index) => (
                                <p key={index}>{line}</p>
                            ))}
                        </p>
                    </div>
                    <div className='flex flex-col gap-0 mt-3'>
                        <p className='text-sm text-[#222] font-semibold'>{t('Whatnottodo')}</p>
                        <p className='text-[13px] text-themeBlack-200 leading-[18px]'>
                            {campaign?.what_not_to_do?.split('\n').map((line, index) => (
                                <p key={index}>{line}</p>
                            ))}
                        </p>
                    </div>
                </div>

                <div className='w-[25%] h-full mt-3 flex flex-col gap-10 sm:w-full'>
                    <div className='flex flex-col gap-1'>
                        <p className='text-sm text-[#222] font-semibold'>{t('TitleKeyword')}</p>
                        <div className='flex flex-col gap-1'>
                            {campaign?.title_keyword?.map((keyword, index) => (
                                <p className='text-themeBlack-200 font-medium text-xs bg-themeGrey-500/30 rounded-md px-4 py-1'>
                                    {keyword}
                                </p>
                            ))}
                        </div>
                    </div>
                    <div className='flex flex-col gap-1'>
                        <p className='text-sm text-[#222] font-semibold mt-'>{t('BodyKeyword')}</p>
                        <div className='flex flex-col gap-1'>
                            {campaign?.body_keyword?.map((keyword, index) => (
                                <p className='text-themeBlack-200 font-medium text-xs bg-themeGrey-500/30 rounded-md px-4 py-1'>
                                    {keyword}
                                </p>
                            ))}
                        </div>
                    </div>
                    <div className='flex flex-col gap-1'>
                        <p className='text-sm text-[#222] font-semibold mt-'>{t('_tags')}</p>
                        <div className='flex gap-1'>
                            {campaign?.tags?.map((keyword, index) => (
                                <p className='text-themeBlack-200 font-medium text-xs bg-themeBlue/30 rounded-md px-4 py-1'>
                                    #{keyword}
                                </p>
                            ))}
                        </div>
                    </div>
                </div>
            </div> */}
        </SkeletonTheme>

    )
}

export default CampDetailSekelton
