import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import ErrorBoundary from './components/ErrorBoundary';
import en from './languages/en/global.json'
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';
import NotificationProvider from './Provider/NotificationProvider';
import korean from './languages/kor/global.json'
import LangaugeProvider from './Provider/LangaugeProvider';
import NavbarProvider from './Provider/NavbarProvider';
import "@babel/polyfill";


i18next.init({
  interpolation: { escapeValue: false },
  lng: 'kor', // Set the default language
  fallbackLng: 'en', // Set the fallback language
  resources: {
    en: {
      global: en,
    },
    kor: {
      global: korean,
    },
  },
});



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ErrorBoundary>
    <I18nextProvider i18n={i18next}>
      <BrowserRouter>
        <LangaugeProvider>
          <NavbarProvider>
            <NotificationProvider>
              <div className='-mt-6'>
                <App />
              </div>
            </NotificationProvider>
          </NavbarProvider>
        </LangaugeProvider>
      </BrowserRouter>
    </I18nextProvider>
  </ErrorBoundary>
);

