import React from 'react'
import ProfileLayout from '../layouts/ProfileLayout'
import { Navigate, Route, Routes } from 'react-router-dom'
import MyData from '../components/MyData'
import ProfileSettings from '../services/ProfileSettings'
import MyPage from '../components/MyPage'

function MyProfile() {
  return (
    <ProfileLayout>
      <Routes>
        <Route index element={<Navigate to='/profile/my-page' />} />
        <Route path='/my-page' element={<MyData />} />
        {/* <Route path='/my-page' element={<MyPage />} />
        <Route path='/my-data' element={<MyData />} />
        <Route path='/faq' element={<h1>My Faq</h1>} />
        <Route path='/settings' element={<ProfileSettings />} /> */}
      </Routes>
      
    </ProfileLayout>
  )
}

export default MyProfile
