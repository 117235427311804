import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { formateDate } from '../utils/formateDate'

function CampaignSchedule({ campaign, scheduleExpanded }) {
    const [t] = useTranslation("global")
    return (
        <div className={`w-full ${scheduleExpanded ? 'h-auto mt-3 ' : 'h-0'} flex flex-col gap-1 overflow-hidden transition-all ease-in duration-100`}>
           <p className='flex justify-between items-center gap-3 text-themeBlack-300 text-sm font-medium'>
                <span>{t('RegisterDate')}</span>
                <span>{formateDate(campaign.registerDate)}</span>
           </p>
           <p className='flex justify-between items-center gap-3 text-themeBlack-300 text-sm font-medium'>
                <span>{t('startDate')}</span>
                <span>{formateDate(campaign.startDate)}</span>
           </p>
           <p className='flex justify-between items-center gap-3 text-themeBlack-300 text-sm font-medium'>
                <span>{t('applicationPeriod')}</span>
                <span>{formateDate(campaign.application_deadline)}</span>
           </p>
          {campaign.campaign_type ==='Store' && <p className='flex justify-between items-center gap-3 text-themeBlack-300 text-sm font-medium'>
                <span>{t('reservation_date')}</span>
                <span>{formateDate(campaign.visit_date)}</span>
           </p>}
           <p className='flex justify-between items-center gap-3 text-themeBlack-300 text-sm font-medium'>
                <span>{t('content_period')}</span>
                <span>{formateDate(campaign.content_deadline)}</span>
           </p>
           <p className='flex justify-between items-center gap-3 text-themeBlack-300 text-sm font-medium'>
                <span>{t('end_date')}</span>
                <span>{formateDate(campaign.endDate)}</span>
           </p>
        </div>
    )
}

export default CampaignSchedule
