import React from 'react'
import { PLATFORMS } from '../constants'
import { Context } from '../Provider/LangaugeProvider';

function MobilePlatformSelection({ scrolled, setQuery, query }) {
    const { siteLang } = React.useContext(Context)
    return (
        <div className={`hidden sm:flex flex-wrap  gap-1 pb-2 pt-4 px-6 scrollbar-hide`}>
            {PLATFORMS.map(p => ({ value: p.key, label: siteLang === 'kor'? p.kor_title: p.title, icon: p.logo })).map((option, index) => (
                <button
                    onClick={() => {
                        if (query?.platform === option.value) {
                            setQuery((prev) => ({ ...prev, platform: '' }));
                        }
                        else {
                            setQuery((prev) => ({ ...prev, platform: option.value }));
                        }
                    }}
                     className={`text-xs gap-2 flex justify-start items-center whitespace-nowrap font-medium px-3 pr-3 outline-none py-1.5 border-[1px] rounded-md ${option?.value === query?.platform ? 'text-themePink border-themePink' : 'border-themeGrey-50 text-themeBlack-900 sm:font-medium'}`}>
                    <img src={option.icon} alt='icon' className='w-3 h-3 object-cover' />
                    {option?.label}
                </button>
            ))}
        </div>
    )
}

export default MobilePlatformSelection
