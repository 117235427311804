import React, { useEffect, useState } from 'react'
import { defaultPfp } from '../utils/defaultPfp'
import { GoGear, GoPencil } from "react-icons/go";
import { useLocation, useNavigate } from 'react-router-dom';
import { IMAGE_URL, PLATFORMS } from '../constants';
import { toast } from 'react-toastify';
import ProfileImage from './ProfileImage';
import { getMyProfile } from '../services/getMyProfile';
import { IoMdLink } from 'react-icons/io';
import { formateDate } from '../utils/formateDate';
import { useTranslation } from 'react-i18next';

function ProfileBio({ user:_user }) {
    const [user, setuser] = useState(_user)
    const localtion = useLocation()
    const navigate = useNavigate()
    const [t] = useTranslation('global')

    useEffect(() => {
        const getUserProfile = async () => {
            const data = await getMyProfile()
            setuser(data)
        }
        getUserProfile()
    }, []) 
    return (
        <div className={`bg-white rounded-lg w-full h-full px-5 py-6 ${localtion.pathname === '/profile/my-page'?  'sm:pt-2' : 'sm:pt-2'} sm:pb-4 border-[1px] border-themeGrey-500 sm:border-none`}>
            <div className='flex justify-start items-start gap-3'>
                {/* <img src={user.profilePic? `${IMAGE_URL}/uploads/${user.profilePic}` : defaultPfp()} alt='refresh-window' className='w-16 h-16 rounded-full bg-themePink/10' /> */}
                <ProfileImage uploadedImage={user.profilePic? `${IMAGE_URL}/uploads/${user?.profilePic}` : null} />
                <div className='flex flex-col gap-1 py-2 sm:w-[60%] overflow-hidden justify-start items-start'>
                   <div className='flex justify-between items-end w-full gap-4'>
                        <div className='flex flex-col gap-1 items-center'>
                                <p className='text-themeBlack-300 rounded-md px-2 py-0.5 text-xs border-[1px] border-themeGrey-70'>{user.level}</p>
                                <p className='text-themeBlack-300 text-xs'>{formateDate(user.createdAt)}</p>
                        </div>
                        <div className='flex flex-col gap-1 items-center'>
                                <p className='text-themeBlack-300 text-xs'>{user.role === 'creator' ? user.totalActiveOrders: user.totalActiveCamapaigns || 0}</p>
                                <p className='text-themeBlack-300 text-xs'>{t('active')}</p>
                        </div>
                        <div className='flex flex-col gap-1 items-center'>
                                <p className='text-themeBlack-300 text-xs'>{user.role === 'creator'?  user.totalCompletedOrders : user.totalCompletedCampaigns || 0}</p>
                                <p className='text-themeBlack-300 text-xs'>{t('Completed')}</p>
                        </div>
                   </div>
                   {user.nickname && <p 
                    onClick={() => {
                        const link = `${window.location.origin}/link/${user.nickname}`;
                        navigator.clipboard.writeText(link);
                        toast.success('Copied to clipboard!');
                      }}
                   className='flex justify-start items-center gap-2 text-xs w-auto text-themePink lowercase'><IoMdLink className='text-lg'/> {`${window.location.origin}/link/${user.nickname}`}</p>}
                </div>
              
            </div>
        </div>
    )
}

export default ProfileBio
