import React from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

function CampaignCardSkeleton() {
    return (
        <div>

            <SkeletonTheme baseColor="#F3F3F3" highlightColor="#E5E5E5">
                <div className='w-64 h-64 rounded-xl overflow-hidden sm:h-72 sm:w-full' >
                    <Skeleton count={1} width={"100%"} height={"100%"} style={{ borderRadius: "12px" }} />
                </div>
                <div className='flex flex-col gap-0'>
                    <Skeleton count={1} width={"100%"} height={13} style={{ borderRadius: "12px" }} />
                    <span className='-mt-2'></span>
                    <Skeleton count={1} width={"100%"} height={13} style={{ borderRadius: "12px" }} />
                </div>
            </SkeletonTheme>
        </div>
    )
}

export default CampaignCardSkeleton
