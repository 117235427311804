import React from 'react'
import { useTranslation } from 'react-i18next'

function CampaignStatus({ campaign, statusExpanded }) {
    const [t] = useTranslation("global")
    return (
        <div className={`w-full ${statusExpanded ? 'h-auto mt-3 ' : 'h-0'} flex flex-col gap-1 overflow-hidden transition-all ease-in duration-100`}>
            <p className='flex justify-between items-center gap-3 text-themeBlack-300 text-sm font-medium'>
                <span>{t('Recurite')}</span>
                <span>{campaign.creator_count}</span>
            </p>
            <p className='flex justify-between items-center gap-3 text-themeBlack-300 text-sm font-medium'>
                <span>{t('apply')}</span>
                <span>{campaign.apply}</span>
            </p>
            <p className='flex justify-between items-center gap-3 text-themeBlack-300 text-sm font-medium'>
                <span>{t('approve')}</span>
                <span>{campaign.approve}</span>
            </p>

            <hr className='flex w-full h-0.5 border-b-[1px] border-themeGrey-70 border-t-0 my-2' />

            {campaign.campaign_type === 'Store' && <p className='flex justify-between items-center gap-3 text-themeBlack-300 text-sm font-medium'>
                <span>{t('Visitt')}</span>
                <span>{campaign.visited}</span>
            </p>}
            <p className='flex justify-between items-center gap-3 text-themeBlack-300 text-sm font-medium'>
                <span>{t('Delivered')}</span>
                <span>{campaign.delivered}</span>
            </p>
            <hr className='flex w-full h-0.5 border-b-[1px] border-themeGrey-70 border-t-0 my-2' />
            <p className='flex justify-between items-center gap-3 text-themeBlack-300 text-sm font-medium'>
                <span>{t('Submissions')}</span>
                <span>{campaign.delivered}</span>
            </p>
            <p className='flex justify-between items-center gap-3 text-themeBlack-300 text-sm font-medium'>
                <span>ㄴ {t('approved')}</span>
                <span>{campaign.totalApproved}</span>
            </p>
            <p className='flex justify-between items-center gap-3 text-themeBlack-300 text-sm font-medium'>
                <span>ㄴ {t('TotalDisputed')}</span>
                <span>{campaign.totalDisputed}</span>
            </p>
            <hr className='flex w-full h-0.5 border-b-[1px] border-themeGrey-70 border-t-0 my-2' />
            <p className='flex justify-between items-center gap-3 text-themeBlack-300 text-sm font-medium'>
                <span>{t('Completed')}</span>
                <span>{campaign.totalApproved}</span>
            </p>
            <p className='flex justify-between items-center gap-3 text-themeBlack-300 text-sm font-medium'>
                <span>{t('Cancel')}</span>
                <span>{campaign.totalCanceled}</span>
            </p>
            <p className='flex justify-between items-center gap-3 text-themeBlack-300 text-sm font-medium'>
                <span>{t('Rejected')}</span>
                <span>{0}</span>
            </p>
        </div>
    )
}

export default CampaignStatus
