import React, { useEffect } from 'react'
import LayoutHeader from '../components/LayoutHeader'
import { useTranslation } from 'react-i18next'
import { getMyBalanceHistory } from '../services/getMyBalanceHistory'
import { convertToSeoulTime } from '../utils/convertToSeoulTime'
import Guide from '../components/Guide'
import { formateDate } from '../utils/formateDate'
import MobileTopHeader from '../components/MobileTopHeader'

function Wallet() {
    const [t] = useTranslation("global")
    const [data, setData] = React.useState([ ])
    const user = JSON.parse(localStorage.getItem('user') || '{}')
    const [totals, setTotals] = React.useState({
        totalEarned: 0,
        totalWithdrawn: 0,
        AvailableBalance: 0
    })


    useEffect(() => {
        let totalWithdrawn = 0
        let AvailableBalance = 0

        data.forEach((item) => {
            if (item.status === 'Pending')
                AvailableBalance += (item.AvailableBalance || 0)
            else
                totalWithdrawn += (item.totalWithdrawn || 0)
        })
        setTotals({
            totalEarned: totalWithdrawn + AvailableBalance,
            totalWithdrawn,
            AvailableBalance
        })
    }, [data])


    useEffect(() => {
        const getData = async () => {
            const _data = await getMyBalanceHistory()
            setData(_data)
        }
        getData()
    }, [])

    return (
        <>
        <MobileTopHeader user={user}/>
        <div className='sm:px-6 sm:pb-20 sm:-mt-4'>
            <LayoutHeader title={t('mywallet')} />
            <h2 className='hidden sm:flex text-themeBlack-300 text-lg font-bold pt-5'>{t('mywallet')}</h2>
            <hr className='w-full h-1 border-t-0 my-2 mb-4 border-b-[2px] border-themeGrey-70 shadow-sm' />
            <div className='w-[30%] sm:w-full mb-4'>
                <Guide heading={t('information')} text={t('information_text')} />
            </div>
            <div className='w-full flex gap-5 sm:flex-col'>
                <div className='bg-white flex-1 border-[1px] shadow-sm border-themeGrey-70 rounded-xl p-6 w-full h-20 flex gap-1 justify-between items-center'>
                    <h2 className='text-sm font-medium text-themeBlack-200'>{t('available')}</h2>
                    <h2 className='text-lg font-bold text-themePink'>{totals.AvailableBalance.toLocaleString()}</h2>
                </div>
                <div className='bg-white flex-1 border-[1px] shadow-sm border-themeGrey-70 rounded-xl p-6 w-full h-20 flex gap-1 justify-between items-center'>
                    <h2 className='text-sm font-medium text-themeBlack-200'>{t('withdrawn')}</h2>
                    <h2 className='text-lg font-bold text-themePink'>{totals.totalWithdrawn.toLocaleString()}</h2>
                </div>
                <div className='bg-white flex-1 border-[1px] shadow-sm border-themeGrey-70 rounded-xl p-6 w-full h-20 flex gap-1 justify-between items-center'>
                    <h2 className='text-sm font-medium text-themeBlack-200'>{t('totalEarned')}</h2>
                    <h2 className='text-lg font-bold text-themePink'>{totals.totalEarned.toLocaleString()}</h2>
                </div>
            </div>


            {data.length > 0 && <div className='flex flex-col gap-4 w-[60%] sm:w-full mt-4'>

                {data.length > 0 && data.map((item, index) => (
                    <div className='flex gap-4 border-[1px] rounded-lg bg-white border-themeGrey-70 p-4'>
                        <img className='w-24 h-24 rounded-md object-cover' src={item.campaign_image}/>
                        <div className='flex flex-col w-full'>
                            <div className='flex justify-between gap-4'>
                                <p className='flex-1 text-themeBlack-300 text-base sm:text-sm'>{item.campaign}</p>
                                <p className='text-themePink text-sm capitalize sm:text-xs'>{item.status}</p>
                            </div>
                            <p className='text-themeBlack-400 text-base sm:text-sm'>{formateDate(item.added_on)}</p> 
                            <div className='flex justify-between items-center mt-5'>
                                <p className='text-white bg-themeBlue/40 rounded-xl text-xs px-4 py-1'>{item.type}</p>
                                <p className='text-lg font-semibold text-themeBlack-300'>{Number(item.balance || 0).toLocaleString()}</p>
                            </div>
                        </div>

                    </div>
                ))}
                </div>}
            {data.length === 0 &&
                <p className='text-xs text-themeGrey-300 mt-3'>{t('no_activity')}</p>
            }
        </div>
        </>
    )
}

export default Wallet
