

import { BASE_URL } from "../constants";
import { getToken } from "../utils/getToken";

export const  getBanks = async (options = false, siteLang) => {
    try {
        const response = await fetch(`${BASE_URL}/bank`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                token: getToken()
            }
        })
        const res = await response.json()
        const banks = []
        for (let i = 0; i < res.banks.length; i++) {
            banks.push({
                value: res.banks[i].eng_name,
                label: siteLang === 'eng'? res.banks[i].eng_name : res.banks[i].kor_name
            })
        }
        if(options) {
            return banks
        }
        return res.banks
    } catch (error) {
        console.log(error);
    }
    return null
}