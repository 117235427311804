import { BASE_URL } from "../constants";
import { getToken } from "../utils/getToken";

export const deliverOrder = async (data) => {
    try {
        const response = await fetch(`${BASE_URL}/order/deliverOrder`,{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'token': getToken()
            },
            body: JSON.stringify(data)
        })
       return response;
    } catch (error) {
        console.log(error);
    }
    return null
}