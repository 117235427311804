

import { BASE_URL } from "../constants";

export const  fetchUserProfile = async (name) => {
    
    try {
        const response = await fetch(`${BASE_URL}/user/profile/?name=${name}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        })
        const res = await response.json()
        return res.user
    } catch (error) {
        console.log(error);
    }
    return null
}