import React, { useRef, useEffect, memo } from 'react';

function Textarea({
  placeholder,
  onChange,
  value,
  required,
  name,
  readOnly = false,
}) {
  const textareaRef = useRef(null);

  useEffect(() => {
    if (textareaRef.current) {
      // Adjust the height on mount if there's any initial value
      adjustHeight(textareaRef.current);
    }
  }, [value]);

  const adjustHeight = (textarea) => {
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  const handleInput = (e) => {
    adjustHeight(e.target);
    if (onChange) {
      onChange(e);
    }
  };

  return (
    <textarea
      id={name}
      ref={textareaRef}
      name={name}
      required={required}
      onChange={handleInput}
      defaultValue={value}
      placeholder={placeholder}
      readOnly={readOnly}
      className={`border-none text-sm outline-none text-themeBlack-300 placeholder:text-themeGrey-150 bg-transparent min-h-20 w-full resize-none`}
      style={{ overflow: 'hidden' }}
    />
  );
}

export default memo(Textarea);
