import React, { useEffect } from 'react'
import { motion } from 'framer-motion'
import Button from '../Button'
import Input from '../Input'
import { FaLock } from 'react-icons/fa'
import PasswordChecker from '../PasswordChecker'
import { validatePassword } from '../../constants/validations'
import { createPassward } from '../../services/createPassward'
import { getMyProfile } from '../../services/getMyProfile'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

function Section5() {
    const [loading, setLoading] = React.useState(false)
    const [activeBtn, setActiveBtn] = React.useState(false)
    const [data, setData] = React.useState()
    const [error, setError] = React.useState('')
    const [t] = useTranslation('global')
    const location = useLocation()
    const onChange = (e) => {
        setError(null)
        setData({ ...data, [e.target.name]: e.target.value })
    }

    const onClick = async() => {
        if(data?.password !== data?.conf_Pass){
            return setError('Password does not match')
        }
        setLoading(true)
        const x = await createPassward(data.password)
        if(x){
            localStorage.removeItem('newUser')
            await getMyProfile()
            window.location.pathname = '/profile/my-page'
        }
        setLoading(false)
    }

    useEffect(() => {
        const { completionPercentage: pass } = validatePassword(data?.password ?? '')
        const { completionPercentage: conf } = validatePassword(data?.conf_Pass ?? '')
        if (pass === 100 && conf === 100) {
            setActiveBtn(true)
        }
        else
            setActiveBtn(false)
    }, [data])
    return (
        <div
        className='w-full bg-white rounded-[50px] shadow-sm h-auto sm:h-screen flex justify-center items-center sm:rounded-none sm:shadow-none'>
        <div className='flex justify-center items-start sm:items-center sm:justify-start sm:py-24 pb-36 flex-col gap-2 py-14 px-20 sm:px-0 relative h-full w-full sm:w-[80%]'>
             <h1 className='text-[32px] leading-[42px] font-Pretendard font-bold text-themePink text-center '>{t('create_pass')}</h1>
                <div className='flex flex-col gap-4 mt-10 w-full'>
                    <Input
                        label={t('new_pass')}
                        type={"password"}
                        placeholder={t('your_password')}
                        required={true}
                        name={"password"}
                        onChange={onChange}
                        icon={<FaLock className="text-base text-themeGrey-150 mt-1" />}
                    />
                    <PasswordChecker password={data?.password ?? ""} />
                    <Input
                        label={t("confirm_pass")}
                        type={"password"}
                        placeholder={t('your_password')}
                        required={true}
                        name={"conf_Pass"}
                        onChange={onChange}
                        icon={<FaLock className="text-base text-themeGrey-150 mt-1" />}
                    />
                      {error && <p className='text-sm text-center text-red-600'>{error}</p>}
                </div>
                <div className='absolute bottom-5 w-[100%] left-0 px-14 sm:px-0'>
                    <Button disabled={loading} text={location.pathname.includes('/change-password')? t('change') : t("create_acc")} active={activeBtn} onClick={onClick} />
                </div>

            </div>
        </div>
    )
}

export default Section5
