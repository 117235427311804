import React, { useEffect } from 'react'
import { motion } from 'framer-motion'
import Button from '../Button'
import SecondaryButton from '../SecondaryButton'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import OTPInput from '../OTPInput'
import { ResendOtp } from '../../services/ResendOtp'
import { verifyOTP } from '../../services/verifyOTP'
import { useTranslation } from 'react-i18next'

function Section4() {
    const [otp, setOtp] = React.useState('')
    const [loading, setLoading] = React.useState(false)
    const [error, setError] = React.useState(null)
    const navigate = useNavigate()
    const [todayAskedOTP, setTodayAskedOTP] = React.useState(0)
    const [counter, setCounter] = React.useState(0)
    const [t] = useTranslation('global')
    const location = useLocation()

    const chnageEmial = () => {
        const newUser = JSON.parse(localStorage.getItem('newUser'));
        if (newUser) {
            delete newUser.email;
            localStorage.setItem('newUser', JSON.stringify(newUser));
            navigate('/signup/email')
        } else {
            console.log('No user found in localStorage');
            navigate('/signup')
        }
    }

    const onCLick = async () => {
        if (otp.length !== 6) {
            return
        }
        setError(null)
        setLoading(true)
        const response = await verifyOTP(otp)
        const res = await response.json()
        if (response.status === 200) {
            if (location.pathname.includes('/change-password'))
                navigate('/change-password/create-password')
            else
                navigate('/signup/create-password')
        }
        else {
            setLoading(false)
            setError(res.message)
        }
    }

    const _resendOTP = async () => {
        if (todayAskedOTP >= 5)
            return setError('You have reached maximum limit for today');
        setLoading(true)
        setCounter(60)
        await ResendOtp()
        setLoading(false)
        setTodayAskedOTP(todayAskedOTP + 1)
        localStorage.setItem('todayAskedOTP', todayAskedOTP + 1)
    }

    useEffect(() => {

        if (counter === 1)
            setCounter(0)

        if (counter > 1) {
            setTimeout(() => {
                setCounter(counter - 1)
            }, 1000)
        }

    }, [counter])

    useEffect(() => {
        const todayDate = new Date().getDate();
        const storedDate = localStorage.getItem('today');
        const storedOTPCount = localStorage.getItem('todayAskedOTP') ?? 0;

        if (storedDate && parseInt(storedDate) !== todayDate) {
            localStorage.setItem('todayAskedOTP', 0);
            setTodayAskedOTP(0);
            localStorage.setItem('today', todayDate);
        } else {
            setTodayAskedOTP(parseInt(storedOTPCount));
        }

        if (location.pathname.includes('/change-password'))
            _resendOTP()
    }, [])
    return (
        <div
            className='w-full bg-white rounded-[50px] shadow-sm h-auto sm:h-screen flex justify-center items-center sm:rounded-none sm:shadow-none'>
            <div className='flex justify-center items-start sm:items-center sm:justify-start sm:py-24 flex-col gap-4 py-14 px-20 sm:px-0 relative h-full w-full sm:w-[80%]'>
                <h1 className='text-[32px] leading-[42px] font-Pretendard font-bold text-themePink text-center w-full sm:text-left sm:text-3xl'>{t('auth')}</h1>
                <p className='text-themeGrey-300 text-base font-normal text-center w-[100%] sm:text-left sm:text-sm'>{t('auth_text')}</p>
                <p className='text-themePink w-full text-base font-medium text-center -mt-3 sm:text-sm'>{JSON.parse(localStorage.getItem("newUser") ?? "{}").email}</p>
                <OTPInput setOtpValue={setOtp} />
                {error && <p className='text-sm text-center w-full text-red-600 -mt-2 sm:text-[12px] sm:text-left'>{error}</p>}
                {!location.pathname.includes('/change-password') && <p onClick={chnageEmial} className='text-themePink text-base font-bold text-center w-full cursor-pointer sm:text-sm'>{t('diff_mail')}</p>}
                <Button active={otp.length === 6 ? true : false} disabled={loading} text={t("Continue")} onClick={onCLick} />
                <SecondaryButton onClick={_resendOTP} disabled={loading || counter > 1} text={`${t('rensend')} ${counter > 1 ? `in ${counter}` : ""}`} />
            </div>
        </div>
    )
}

export default Section4
