import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BsBox, BsBoxFill } from 'react-icons/bs'
import { FaList, FaListUl } from 'react-icons/fa'
import { PiStorefrontDuotone, PiUser, PiWalletLight } from 'react-icons/pi'
import { TbTimeDuration10 } from 'react-icons/tb'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'

function MobileLandingNav({ query, setQuery }) {
    const [t] = useTranslation('global');
    const location = useLocation();
    const navigate = useNavigate();
    const loggedIn = localStorage.getItem('token') && localStorage.getItem('user') ? true : false;
    const user = JSON.parse(localStorage.getItem('user') || '{}');

    const [isNavVisible, setIsNavVisible] = useState(true);
    const [scrollTimeout, setScrollTimeout] = useState(null);

    const handleScroll = () => {
        setIsNavVisible(false);
        if (scrollTimeout) {
            clearTimeout(scrollTimeout);
        }
        const newTimeout = setTimeout(() => {
            setIsNavVisible(true);
        }, 150); // Show nav after scrolling stops
        setScrollTimeout(newTimeout);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
            if (scrollTimeout) clearTimeout(scrollTimeout);
        };
    }, [scrollTimeout]);


    if(
        location.pathname.includes('/login') ||
        location.pathname.includes('/signup') ||
        location.pathname.includes('/change-password') ||
        location.pathname.includes('/link') ||
        location.pathname.includes('/create') ||
        location.pathname.includes('/edit') ||
       ( location.pathname.includes('/inbox') && location.pathname.length > 6)
    )
    return <></>


    return (
        <div className={`hidden sm:flex flex-col items-stretch fixed bottom-0 bg-white left-0 w-full border-t-[1px] z-50 border-themeGrey-70 transition-transform ${isNavVisible ? 'translate-y-0' : 'translate-y-full'} duration-150`}>
            <div className='flex justify-center gap-4 z-10 bg-white items-center px-6 py-2 w-full mobile-nav'>
                <div onClick={() => {setQuery({ ...query, type: "Product" }); navigate('/')}} className={` ${location.pathname === '/' && query.type == 'Product' ? 'active' : ''} flex flex-1 flex-col justify-center items-center gap-[0.5] text-themeBlack-300 text-xs`}>
                    <BsBox className={`${location.pathname === '/' &&  query.type == 'Product' ? 'text-themePink' : 'text-themeBlack-300'} opacity-65 text-2xl my-1`} />
                    {t('Product')}
                </div>
                <div onClick={() => {setQuery({ ...query, type: "Store" }); navigate('/')}} className={` ${location.pathname === '/' && query.type == 'Store' ? 'active' : ''} flex flex-1 flex-col justify-center items-center gap-[0.5] text-themeBlack-300 text-xs`}>
                    <PiStorefrontDuotone className={`${location.pathname === '/' &&  query.type == 'Store' ? 'text-themePink' : 'text-themeBlack-300'} opacity-65 text-3xl my-[1px]`} />
                    {t('Store')}
                </div>
                <div onClick={() => {setQuery({ ...query, type: "10%" }); navigate('/')}} className={` ${location.pathname === '/' &&  query.type == '10%' ? 'active' : ''} flex flex-1 flex-col justify-center items-center gap-[0.5] text-themeBlack-300 text-xs`}>
                    <TbTimeDuration10 className={`${location.pathname === '/' &&  query.type == '10%' ? 'text-themePink' : 'text-themeBlack-300'} opacity-65 text-3xl my-0.5`} />
                    {t('10%')}
                </div>
                <NavLink to={user.role === 'customer' ? '/manage' : '/orders'} className={` flex flex-1 flex-col justify-center text-nowrap items-center gap-[0.5] text-themeBlack-300 text-xs`}>
                    <FaList className={`${(location.pathname.startsWith('/manage') || location.pathname.startsWith('/orders') )? 'text-themePink' : 'text-themeBlack-300'} opacity-65 text-2xl my-1`} />
                    {t('manage')}
                </NavLink>
                <NavLink to={'/profile'} className={` flex flex-1 flex-col justify-center items-center gap-[0.5] text-nowrap text-themeBlack-300 text-xs`}>
                    <PiUser className={`${location.pathname.startsWith('/profile')? 'text-themePink': 'text-themeBlack-300'} opacity-65 text-[27px] my-1`} />
                    {t('MyPage')}
                </NavLink>
                
            </div>

        </div>
    )
}

export default MobileLandingNav
