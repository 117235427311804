import React from 'react'

function Switch({
    value = false,
    onChange = () => {},
    name,
    placeholder = 'placeholder',
}) {

    
  return (
    <div className='flex justify-between items-center w-full h-9'>
        <p className='text-themeBlack-400 text-sm w-[80%]'>{placeholder}</p>
        <div
        onClick={() => {
            onChange({
                target: {
                    name: name,
                    value: !value
                }
            })
        }}
        className={`w-10 ${value? 'bg-[#2db40067]' : 'bg-[#E4E4E4]'} h-[14px] rounded-3xl cursor-pointer relative `}>
            <div className={`absolute ${value? 'right-0 bg-themePink' : 'bg-[#C5C5C5] left-0'}  h-[20px] w-[20px] -top-[3px] shadow-sm rounded-full transition-all ease-in duration-100`}>
            </div>
        </div>
    </div>
  )
}

export default Switch
