import { Link, useNavigate } from "react-router-dom"
import Button from "../Button"
import { motion } from 'framer-motion'

const Section0 = () => {
    const navigate = useNavigate()
    return (
        <motion.div key="section2"
            initial={{ x: '100%' }}
            animate={{ x: 0 }}
            exit={{ x: '-100%' }}
            transition={{ duration: 0.3 }}
            className='w-full bg-white rounded-l-[50px] shadow-2xl h-full flex justify-center items-center sm:rounded-none sm:shadow-none'>
            <div className='flex justify-center flex-col gap-3 py-10 px-0 relative h-full w-[60%] sm:w-[80%]'>
                <h1 className='text-[32px] leading-[42px] font-Pretendard font-bold text-themePink text-center mb-12 sm:text-3xl'>Create an account in just 1 minute!</h1>
                <Button text="Identification" onClick={() => navigate('/signup/select-role')} active={true} />
                <div className='flex justify-center items-center gap-1'>
                    <input type="checkbox" className='checkbox sm:mt-0.5' />
                    <p className='sm:text-sm text-themeBlack-400'>I agree with the <Link to='/terms' className='text-themePink underline'>terms</Link> and <Link to="/conditions" className='text-themePink underline'>conditions</Link>.</p>
                </div>
                <div className='absolute bottom-5 w-[100%] left-0'>
                    <p className='text-center  mx-auto flex justify-center items-center gap-1 sm:text-sm text-themeBlack-400'>Already have an account? <Link to="/login" className='text-themePink'>Sign In</Link>.</p>
                </div>
            </div>
        </motion.div>
    )
}

export default Section0