import React, { useContext, useEffect, useRef } from 'react'
import Select from './Select'
import { Context } from '../Provider/LangaugeProvider';
import { FaRegDotCircle } from 'react-icons/fa';
import { sortOptions } from '../constants';

function SortPopup({ setQuery, query, setSort, scrolled }) {

    const {siteLang} = useContext(Context)


    return (
        <div  className={`flex absolute ${scrolled? 'top-14 right-[0%]' : 'top-28 right-[0%] sm:top-8 sm:right-0'} duration-100 w-[250px]  bg-white z-50`}>
            <div className={`absolute flex flex-col  right-0 top-0 w-full rounded-lg bg-white border-[1px] border-themeGrey-100 z-40 shadow-md overflow-hidden mb-10`}>
            <ul className='w-full h-full overflow-y-auto z-50 relative'>
                    {sortOptions.map((option, index) => (
                        <li
                            key={index}
                            onClick={() => {
                                    setQuery((prev) => ({ ...prev, sort: option.value }));
                                setSort(false);
                            }}
                            className={`flex justify-between items-center py-2 px-4 gap-1 ${option?.value === query?.sort && 'bg-white'} hover:bg-white cursor-pointer text-sm border-b-[1px] border-themeGrey-100 text-themeBlack-300`}
                        >
                            <div className='flex justify-start items-center gap-2'>
                                {siteLang === 'kor'? option.kor_label: option?.label}
                            </div>
                            <FaRegDotCircle className={`${option?.value === query?.sort ? 'text-themePink' : 'text-themeBlack-400'} text-sm ml-4`} />
                        </li>
                    ))}
                </ul>
                <div className='absolute bottom-0 w-1/2 h-10 bg-themePink blur-3xl opacity-60 z-0' />
                <div className='absolute -top-2 right-0 w-1/2 h-10 bg-themePink blur-3xl opacity-50 z-0' />
            </div>
        </div>
    )
}

export default SortPopup
